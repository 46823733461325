import Spinner from "./Spinner";

export default function Splash() {
  return (
    <div className="grid h-screen grid-rows-[auto] items-center justify-items-center bg-blue-600 bg-wave bg-cover bg-center bg-no-repeat">
      <div className="mt-40">
        <img
          src={"/labs-icon.png"}
          className="h-40"
          alt="labs-logo"
          loading="lazy"
        />
      </div>
      <div className="mb-64">
        <Spinner className="h-20 w-20 border-[5px] text-blue-600" />
      </div>
    </div>
  );
}
