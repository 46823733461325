import { useState } from "react";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import { getter } from "../../api";
import {
  AutocompleteResult,
  Option,
  TechnologiesDetail,
} from "../../api/types";
import Select from "./Select";

function formatTechnologiesOption(result: TechnologiesDetail) {
  return (
    <div className="flex items-center gap-x-3">
      <div>
        <p>{result.label}</p>
      </div>
    </div>
  );
}

type TechnologiesSelectProps = {
  isMulti: boolean;
  id: string;
  value: Option;
  onChange: (selected: any) => void;
};

export default function TechnologiesSelect({
  ...props
}: TechnologiesSelectProps) {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const { data } = useSWR<AutocompleteResult[]>(
    `/api/people_map/autocomplete/technologies?q=${debouncedQuery}`,
    getter,
  );
  return (
    <Select
      options={data}
      onInputChange={setQuery}
      formatOptionLabel={formatTechnologiesOption}
      isClearable
      {...props}
    />
  );
}
