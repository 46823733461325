export const GLOBAL_GROUP_KEY = "/initial-global-group-selected";
export const DEFAULT_INVALID_PAGE_MESSAGE =
  "The resource does not exist or you do not have permission to access it";
export const SESSION_EXPIRED_MESSAGE =
  "Your Session has Expired. Please Logout and Re-Login to Access File Attachments.";
export const HttpCodes = {
  NOT_FOUND: 404,
  SESSION_EXPIRED: 401,
};

//Contact session key
export const CREATE_CONTACT_FORM_KEY = "create_contact_form";
export const DEFAULT_ERROR_MESSAGE =
  "Please contact labs.support@vertexholdings.com for assistance.";
