import React, { useState } from "react";

// https://stackoverflow.com/a/70710316
// Modified to return only one letter when there are no spaces.
function getAvatar(name: string) {
  const hasTokens = name.indexOf(" ") !== -1;
  return (
    name.charAt(0) + (hasTokens ? name.charAt(name.lastIndexOf(" ") + 1) : "")
  );
}

// Adapted from https://medium.com/@pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66,
// which might have been influenced by https://stackoverflow.com/a/21682946.
function stringToColor(s: string) {
  const colors = [
    "#86efac",
    "#93C5FD",
    "#7dd3fc",
    "#a5b4fc",
    "#d8b4fe",
    "#fdba74",
    "#fda4af",
    "#cbd5e1",
  ];

  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    hash = s.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash % colors.length);

  return colors[index];
}

type AvatarProps = {
  name?: string;
  size: number;
  className?: string;
  url?: string;
};

const Avatar: React.FC<AvatarProps> = ({
  name = "",
  size,
  className = "",
  url,
}) => {
  const [isBrokenImg, setIsBrokenImg] = useState(false);

  const bgColor = stringToColor(name);
  const avatarText = getAvatar(name).toUpperCase();

  const commonProps = {
    className: `grid justify-center items-center text-white rounded-full shrink-0 ${className}`,
    style: {
      backgroundColor: bgColor,
      width: size,
      height: size,
      fontSize: size / 3,
    },
  };

  if (!url || isBrokenImg) {
    return <div {...commonProps}>{avatarText}</div>;
  } else {
    return (
      <div {...commonProps}>
        <img
          {...commonProps}
          alt="Photo"
          loading="lazy"
          src={url}
          onError={() => {
            setIsBrokenImg(true);
          }}
        />
      </div>
    );
  }
};

export default Avatar;
