type ActivityIconProps = {
  className?: string;
};

const ActivityIcon = ({ className }: ActivityIconProps) => {
  return (
    <svg
      viewBox="0 0 24 24"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Activity</title>
      <path d="M17.5,3H7.5A4.505,4.505,0,0,0,3,7.5v10A4.505,4.505,0,0,0,7.5,22h10A4.505,4.505,0,0,0,22,17.5V7.5A4.505,4.505,0,0,0,17.5,3ZM21,17.5A3.5,3.5,0,0,1,17.5,21H7.5A3.5,3.5,0,0,1,4,17.5V7.5A3.5,3.5,0,0,1,7.5,4h10A3.5,3.5,0,0,1,21,7.5Zm-2.216-5a.5.5,0,0,1-.5.5H15.625l-1.684,3.307a.5.5,0,0,1-.445.274H13.47a.5.5,0,0,1-.441-.319l-2.018-5.206-.886,1.678A.5.5,0,0,1,9.682,13H6.716a.5.5,0,0,1,0-1H9.381l1.257-2.382a.494.494,0,0,1,.471-.265.5.5,0,0,1,.438.318l2.011,5.186,1.314-2.584A.5.5,0,0,1,15.318,12h2.966A.5.5,0,0,1,18.784,12.5Z" />
    </svg>
  );
};

export default ActivityIcon;
