import * as HeroIcons from "@heroicons/react/24/solid";
import {
  ArrowRightIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import { AxiosError } from "axios";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
} from "formik";
import Link from "next/link";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Dropzone from "react-dropzone";
import {
  autocompleteNinjaOrganizations,
  autocompleteOrganizations,
  createPerson,
  getApolloPerson,
  updatePerson,
} from "../api";
import {
  ApolloPersonDetail,
  ContactFormValues,
  Error,
  OrganizationAutocompleteResult,
  PersonDetail,
  PydanticError,
} from "../api/types";
import ApolloSuggestion from "./ApolloSuggestion";
import Avatar from "./Avatar";
import ErrorModal from "./ErrorModal";
import FormCompanySelectField from "./Form/FormCompanySelectField";
import FormCountriesSelectField from "./Form/FormCountriesSelectField";
import FormTextField from "./Form/FormTextField";
import { ModalHeader } from "./ModalHeader";
import NewModal from "./NewModal";
import AutocompleteMultiselect from "./Select/AutocompleteMultiselect";
import Spinner from "./Spinner";
import Thumbnail from "./Thumbnail";
import { downloadImageAndConvertToFile, parseAxiosError } from "./utils";
import { CREATE_CONTACT_FORM_KEY } from "./Utils/constant";
import { useScrollToFirstError } from "./Utils/formUtils";
import { ShowSnackBar, SUPPORT_MESSAGE } from "./Utils/supportMessage";

type ModalTitle = "Upload" | "Error";

type ErrorMessageProps = {
  error: AxiosError | undefined;
  setErrors: (errors: FormikErrors<ContactFormValues>) => void;
};

const clearSession = () => {
  if (sessionStorage.getItem(CREATE_CONTACT_FORM_KEY)) {
    sessionStorage.removeItem(CREATE_CONTACT_FORM_KEY);
  }
};

const ErrorMessage = ({ error, setErrors }: ErrorMessageProps) => {
  let errorMessage = SUPPORT_MESSAGE;
  if (error && error.response) {
    const res = error.response;
    if (res.status === 422) {
      // Data does not pass backend validation
      const data: PydanticError = res.data;
      let errors: FormikErrors<ContactFormValues> = {};
      data.detail.map((value) => {
        errors[value.loc[1] as keyof ContactFormValues] = value.msg;
      });
      setErrors(errors);
      errorMessage = <p>Please check values entered</p>;
    } else if (res.status === 403) {
      errorMessage = <p>{parseAxiosError(error)}</p>;
    } else if (res.status === 409) {
      // User already exists
      const error: Error<PersonDetail> = res.data;
      const person: PersonDetail = error.data;
      errorMessage = (
        <div>
          <p>This contact already exists!</p>
          {person ? (
            <Link
              href={`/people/${person.id}`}
              onClick={() => {
                clearSession();
              }}
            >
              <div className="mx-auto mt-3 flex h-28 w-96 cursor-pointer items-center rounded-3xl bg-white p-4 drop-shadow-md">
                <Avatar name={person.name} size={64} url={person.image_url} />
                <div className="m-5 flex-grow">
                  <h4 className="font-semibold">{person.name}</h4>
                  <p className="text-sm">{person.title}</p>
                </div>
                <div className="mx-auto flex h-6 w-6 items-center justify-center rounded-full bg-blue-menu sm:h-10 sm:w-10">
                  <ArrowRightIcon className="h-4 w-4 text-white sm:h-6 sm:w-6" />
                </div>
              </div>
            </Link>
          ) : (
            <div></div>
          )}
        </div>
      );
    } else if (res.status === 404) {
      // No organization
      errorMessage = <p>User not found</p>;
    }
  }

  return errorMessage;
};

function isValidLinkedInPersonURL(url: string) {
  const regex = /^(https?:\/\/)?(www\.)?linkedin\.com\/.+/;
  return regex.test(url);
}

type IconNames = keyof typeof HeroIcons;

type ContactFormProps = {
  contact?: PersonDetail;
  callBack?: (personDetail: PersonDetail) => void;
  setNotFoundError?: Dispatch<SetStateAction<boolean>>;
  title: string;
  icon?: IconNames;
  isModal?: boolean;
};

const ContactForm = ({
  contact,
  callBack,
  title,
  icon,
  isModal,
}: ContactFormProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState<ModalTitle>("Upload");
  const [error, setError] = useState<AxiosError>();
  const formikRef = useRef<FormikProps<ContactFormValues>>(null);
  const [isApolloLoading, setIsApolloLoading] = useState<boolean>(false);
  const [openApollo, setOpenApollo] = useState<boolean>(false);
  const [apolloData, setApolloData] = useState<ApolloPersonDetail | null>(null);
  const [fillApolloData, setFillApolloData] =
    useState<ApolloPersonDetail | null>(null);
  const [photoFromUrl, setPhotoFromUrl] = useState<boolean>(false);

  const [apolloModalMessage, setApolloModalMessage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [previousFormData, setPreviousFormData] = useState<any>(null);
  const [isClearForm, setIsClearForm] = useState<boolean>(false);
  const [countCreate, setCountCreate] = useState<number>(0);
  const [isEmailEdited, setIsEmailEdited] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [openModalError, setOpenModalError] = useState<boolean>(false);
  const [initialData, setInitialData] = useState<any>(null);
  const [formErrors, setFormErrors] =
    useState<FormikErrors<ContactFormValues>>();
  const [submit, setSubmit] = useState<boolean>(false);

  useScrollToFirstError(formErrors, submit, setSubmit);

  const contactToFormValues = (contact: PersonDetail): ContactFormValues => {
    return {
      first_name: contact.first_name,
      last_name: contact.last_name,
      title: contact.title,
      email: contact.email,
      phone: contact.phone,
      organization: {
        value: contact.organization.id,
        label: contact.organization.name,
        domain: contact.organization.domain,
        image_url: contact.organization.image_url,
        description: contact.organization.description,
        country: contact.organization.country,
      },
      past_organizations: contact.past_organizations?.map((org: any) => {
        return {
          value: org.id,
          label: org.name,
          domain: org.domain,
          image_url: org.image_url,
          description: org.description,
          country: org.country,
        };
      }),
      image_url: contact.image_url,
      image: undefined,
      is_email_visible: contact.is_email_visible,
      tags: contact.tags.map((tag: any) => ({
        label: tag.name,
        value: tag.id,
      })),
      linkedin: contact.linkedin,
      country: contact.country,
      city: contact.city,
      email_unknown: false,
    };
  };

  const emptyForm: ContactFormValues = {
    first_name: "",
    last_name: "",
    title: "",
    email: "",
    phone: "",
    organization: null,
    past_organizations: [],
    image_url: "",
    image: undefined,
    is_email_visible: true,
    linkedin: "",
    tags: [],
    country: null,
    city: "",
    email_unknown: false,
  };

  useMemo(() => {
    setInitialData(
      contact && !isClearForm
        ? contactToFormValues(contact)
        : previousFormData
          ? previousFormData
          : emptyForm,
    );
    //eslint-disable-next-line
  }, [contact, previousFormData]);

  // This fetches data from the apollo endpoint
  const getApolloDetails = (values: ContactFormValues) => {
    setIsApolloLoading(true);

    const queryParams = new URLSearchParams();
    values.first_name.length > 0 &&
      queryParams.set("first_name", values.first_name);
    values.last_name.length > 0 &&
      queryParams.set("last_name", values.last_name);
    values.organization?.label &&
      queryParams.set("organization_name", values.organization?.label);
    values.organization?.domain &&
      queryParams.set("organization_domain", values.organization?.domain);
    values.email?.length > 0 && queryParams.set("email", values.email);
    values.linkedin?.length > 0 &&
      queryParams.set("linkedin_url", values.linkedin);

    getApolloPerson(queryParams.toString())
      .then((resp) => {
        setApolloData(resp.data);
      })
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 400:
            setApolloModalMessage(
              "This function has been disabled for you. Please contact Labs Support for more info.",
            );
            return;
          case 404:
            setApolloModalMessage(
              "Sorry, we could not find anyone based on your query.",
            );
            return;
        }
      })
      .finally(() => {
        setIsApolloLoading(false);
      });
  };

  const handleSubmit = (values: ContactFormValues) => {
    const formData = new FormData();
    if (values.image) {
      formData.append("image", values.image);
    }

    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      title: values.title,
      email: values.email.toLowerCase(),
      linkedin: values.linkedin,
      phone: values.phone,
      organization: String(values.organization?.value),
      past_organizations: values.past_organizations.map(
        (org: OrganizationAutocompleteResult) => org.value,
      ),
      tags: values.tags.map((tag) => tag.label),
      country: String(values.country?.value),
      city: values.city,
    };
    if (payload.country === "undefined") {
      payload.country = "";
    }
    if (payload.linkedin === undefined || payload.linkedin === null) {
      payload.linkedin = "";
    }

    formData.append("person", JSON.stringify(payload));
    setIsSubmitting(true);
    const submitFunction = contact
      ? updatePerson(formData as any, contact.id)
      : createPerson(formData as any);
    submitFunction
      .then((res) => {
        clearSession();
        ShowSnackBar(
          contact
            ? "Contact Updated Successfully"
            : "Contact Created Successfully",
        );
        if (callBack) {
          callBack(res.data.data);
        }
      })
      .catch((err: AxiosError) => {
        switch (err.response?.status) {
          case 413:
            setOpenModalError(true);
            setErrorMessage(
              "Error: File size is more than the permitted limit of 32MB.",
            );
            return;
          case 500:
            setOpenModalError(true);
            setErrorMessage(err.message);
            return;
          case 404:
            setOpenModalError(true);
            setErrorMessage("404");
            return;
        }
        setError(err);
        setModalTitle("Error");
        setOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const validate = (values: ContactFormValues) => {
    let errors: FormikErrors<ContactFormValues> = {};

    if (!values.first_name) {
      errors.first_name = "First Name is required";
    }
    if (!values.last_name) {
      errors.last_name = "Last Name is required";
    }
    if (!values.title) {
      errors.title = "Title is required";
    }

    if (values.is_email_visible) {
      if (!values.email) {
        if (
          ((!values.first_name && values.first_name.trim().length === 0) ||
            (!values.last_name && values.last_name.trim().length === 0) ||
            !values.organization) &&
          values.email_unknown
        ) {
          const missingFields = [];
          if (!values.first_name) missingFields.push("First Name");
          if (!values.last_name) missingFields.push("Last Name");
          if (!values.organization) missingFields.push("Organization");
          errors.email = `${missingFields.join(", ")} ${
            missingFields.length > 1 ? "are" : "is"
          } required`;
        } else {
          errors.email = "Email is required";
        }
      } else if (!/^[A-Z\d._%+-]+@[A-Z\d.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = "Email is invalid";
      }
    }
    if (!values.organization) {
      errors.organization = "Organization is required";
    }
    if (values.linkedin && !isValidLinkedInPersonURL(values.linkedin)) {
      errors.linkedin =
        "URL should be https://www.linkedin.com/in/<person-name>";
    }
    if (!values.country) {
      errors.country = "Country is required";
    }
    if (!values.past_organizations) {
    }

    if (!isClearForm) {
      let localData = sessionStorage.getItem(CREATE_CONTACT_FORM_KEY);
      if (localData) {
        let storedData = JSON.parse(localData);
        if (storedData && countCreate < 1) {
          Object.entries(values).forEach(([key, value]) => {
            if (key && value) {
              setCountCreate(1);
            }
          });
        } else {
          if (!contact) {
            sessionStorage.setItem(
              CREATE_CONTACT_FORM_KEY,
              JSON.stringify({ ...values, image: undefined }),
            );
          }
        }
      } else {
        if (!contact) {
          sessionStorage.setItem(
            CREATE_CONTACT_FORM_KEY,
            JSON.stringify({ ...values, image: undefined }),
          );
        }
      }
    }
    setIsClearForm(false);
    setFormErrors(errors);
    return errors;
  };

  function cleanName(name: string) {
    return name.replace(/[^a-zA-Z]/g, "").toLowerCase();
  }

  useEffect(() => {
    if (!contact) {
      const contactFormStr = sessionStorage.getItem(CREATE_CONTACT_FORM_KEY);
      if (contactFormStr) {
        setPreviousFormData(JSON.parse(contactFormStr));
      }
    } else {
      if (contact.image_url) {
        setPhotoFromUrl(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  const updatePhoto = useCallback(
    (values: ContactFormValues) => {
      if (photoFromUrl) {
        if (values.image_url) {
          return <Avatar name="Image" size={48} url={values.image_url} />;
        }
        return <UserIcon className="h-6 w-6 fill-white" />;
      } else {
        if (values.image) {
          return (
            <Thumbnail
              file={values.image}
              className={"h-11 w-11 shrink-0 rounded-full"}
            />
          );
        }
        return <UserIcon className="h-6 w-6 fill-white" />;
      }
    },
    [photoFromUrl],
  );

  return (
    <Formik
      initialValues={
        fillApolloData
          ? {
              ...initialData,
              first_name: fillApolloData.first_name,
              last_name: fillApolloData.last_name,
              title: fillApolloData.title ?? "",
              email: fillApolloData.email,
              phone: "",
              organization: fillApolloData.organization,
              past_organizations: [],
              image_url: fillApolloData.image_url,
              image: fillApolloData.image_file,
              tags: [],
              is_email_visible: true,
              linkedin: fillApolloData.linkedin,
              country: fillApolloData.country,
            }
          : initialData
      }
      onSubmit={handleSubmit}
      validate={validate}
      enableReinitialize={true}
      innerRef={formikRef}
    >
      {({
        errors,
        touched,
        setFieldValue,
        setErrors,
        values,
        resetForm,
        setValues,
        setTouched,
        setFieldTouched,
      }) => {
        const enableApolloQuery =
          values.linkedin ||
          values.email ||
          (values.first_name && values.last_name && values.organization);
        return (
          <>
            <div className="flex items-center space-x-4">
              {!isModal ? (
                <>
                  <div className="h-8 w-2 rounded-full bg-blue-menu" />
                  <h2 className="mt-3 mb-3 text-base font-bold tracking-wide md:text-2xl">
                    {title}
                  </h2>
                </>
              ) : (
                <ModalHeader title={title} icon={icon} />
              )}
              <div className="group relative flex">
                <button
                  onClick={() => {
                    setApolloData(null);
                    getApolloDetails(values);
                    setOpenApollo(true);
                  }}
                  className={`btn-primary ${
                    !enableApolloQuery ? "disabled" : ""
                  }`}
                >
                  {isApolloLoading ? (
                    <div className="flex flex-row items-center gap-x-2 text-white">
                      <div>
                        <Spinner className="h-4 w-4 text-white" />
                      </div>
                      <div>Querying</div>
                    </div>
                  ) : (
                    "Query Contact"
                  )}
                </button>
                <div className="invisible absolute top-9 z-top w-max whitespace-pre-wrap rounded-lg bg-gray-800 p-2 text-xs font-medium text-white group-hover:visible">
                  {`In order to autopopulate person's details from external database, fill either:\n 1) Email\n 2) LinkedIn URL\n 3) First Name, Last Name, and Company`}
                </div>
              </div>
            </div>
            <div className={`grid gap-y-3`}>
              <Form>
                <div className="relative mt-5 grid grid-cols-1 gap-y-4 md:grid-cols-2 lg:gap-y-8">
                  <Field
                    as={FormTextField}
                    name="first_name"
                    label="First name"
                    errors={errors.first_name}
                    touched={touched.first_name}
                    required
                  />
                  <div className="relative flex md:justify-end">
                    <Field
                      as={FormTextField}
                      name="last_name"
                      label="Last name"
                      errors={errors.last_name}
                      touched={touched.last_name}
                      required
                    />
                  </div>
                  <Field
                    as={FormTextField}
                    name="title"
                    label="Title"
                    errors={errors.title}
                    touched={touched.title}
                    required
                  />
                  <div className="relative flex md:justify-end">
                    <Field>
                      {({
                        form: { setFieldValue, errors, touched },
                      }: FieldProps) => {
                        if (!isEmailEdited && values.email_unknown) {
                          if (
                            values.first_name &&
                            values.first_name.trim().length > 0 &&
                            values.last_name &&
                            values.last_name.trim().length > 0 &&
                            values.organization
                          ) {
                            values.email = `unknown-${cleanName(
                              values.first_name,
                            )}.${cleanName(values.last_name)}@${
                              values.organization.domain
                            }`;
                          } else {
                            values.email = "";
                          }
                        }

                        return (
                          <FormTextField
                            name="email"
                            label="Email"
                            errors={
                              errors.email ? String(errors.email) : undefined
                            }
                            touched={Boolean(touched.email)}
                            value={
                              values.email && values.email.trim().length > 0
                                ? values.email
                                : ""
                            }
                            onChange={(event: any) => {
                              setIsEmailEdited(true);
                              let value = event.target.value;
                              setFieldValue("email", value);
                              touched.email = true;
                            }}
                            required={true}
                            disabled={!values.is_email_visible}
                            onBlur={() => {
                              setFieldTouched("email", true);
                            }}
                          />
                        );
                      }}
                    </Field>
                    <Field name="email_unknown">
                      {({ field, form: { setFieldValue } }: FieldProps) => {
                        values.email_unknown =
                          (values.first_name &&
                            values.first_name.trim().length > 0 &&
                            values.last_name &&
                            values.last_name.trim().length > 0 &&
                            values.email &&
                            values.email.trim().length > 0 &&
                            values.organization &&
                            values.email ===
                              `unknown-${cleanName(
                                values.first_name,
                              )}.${cleanName(values.last_name)}@${
                                values.organization.domain
                              }`) ||
                          false;

                        return (
                          <div className="absolute top-0 right-1 flex flex-row items-center gap-x-1">
                            <input
                              type="checkbox"
                              className="h-3.5 w-3.5 cursor-pointer rounded-full border-gray-500 text-blue-600 focus:ring-white disabled:cursor-not-allowed disabled:bg-gray-300"
                              checked={values.email_unknown}
                              onChange={(event) => {
                                setFieldValue(field.name, event.target.checked);
                                touched.first_name = true;
                                touched.last_name = true;
                                touched.organization = true;
                                if (
                                  values.first_name &&
                                  values.first_name.trim().length > 0 &&
                                  values.last_name &&
                                  values.last_name.trim().length > 0 &&
                                  values.organization
                                ) {
                                  setFieldValue(
                                    "email",
                                    event.target.checked
                                      ? `unknown-${cleanName(
                                          values.first_name,
                                        )}.${cleanName(values.last_name)}@${
                                          values.organization.domain
                                        }`
                                      : "",
                                  );
                                  if (event.target.checked) {
                                    setIsEmailEdited(false);
                                  }
                                }
                                initialData.email_unknown =
                                  event.target.checked;
                                touched.email = true;
                              }}
                              disabled={!values.is_email_visible}
                            />
                            <div className="text-xs font-normal">
                              Email unknown
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <Field>
                    {({ form: { errors, touched } }: FieldProps) => {
                      return (
                        <FormCompanySelectField
                          id="contact-form-organization-field"
                          name="organization"
                          label="Company"
                          placeholder={"Select Company"}
                          errors={errors.organization}
                          touched={Boolean(touched.organization)}
                          flexible={true}
                          required
                          withNinja={true}
                          customClass={"w-full md:w-11/12"}
                          onChange={(company: any) => {
                            setValues({
                              ...values,
                              organization: company,
                              country: !values.country
                                ? company.country
                                : values.country,
                            });
                            touched.country = true;
                            touched.organization = true;
                          }}
                          onBlur={() => {
                            setFieldTouched("organization", true);
                          }}
                        />
                      );
                    }}
                  </Field>
                  <div className="relative flex md:justify-end">
                    <Field
                      as={FormTextField}
                      name="phone"
                      label="Phone"
                      errors={errors.phone ? String(errors.phone) : undefined}
                      touched={Boolean(touched.phone)}
                      onBlur={() => {
                        if (!values.phone) {
                          setFieldTouched("phone", false);
                        } else {
                          setFieldTouched("phone", true);
                        }
                      }}
                    />
                  </div>
                  <Field
                    as={FormCountriesSelectField}
                    id="contact-form-country-field"
                    placeholder="Select Country"
                    name="country"
                    label="Country"
                    errors={errors.country}
                    touched={touched.country}
                    required={true}
                    isClearable={true}
                    bool={false}
                    onBlur={() => {
                      setFieldTouched("country", true);
                    }}
                  />
                  <div className="relative flex md:justify-end">
                    <Field
                      as={FormTextField}
                      name="city"
                      label="City"
                      errors={errors.city}
                      touched={touched.city}
                      onBlur={() => {
                        if (!values.city) {
                          setFieldTouched("city", false);
                        } else {
                          setFieldTouched("city", true);
                        }
                      }}
                    />
                  </div>

                  <div className="w-full md:w-11/12">
                    <div className="flex items-end justify-between">
                      <div className="flex">
                        <label
                          className="text-xs font-semibold"
                          htmlFor={"tags"}
                        >
                          Tags
                        </label>
                      </div>
                    </div>
                    <div className="border-1 block rounded-md text-xs">
                      <Field name="tags" className="h-12">
                        {({
                          field: { name, value },
                          form: { setFieldValue, touched },
                        }: FieldProps) => (
                          <AutocompleteMultiselect
                            id="tagsField"
                            autocompleteEndpoint="/api/people_map/autocomplete/meeting_note_tags"
                            selected={value}
                            placeholder="Select tag"
                            onChange={(newValue) => {
                              setFieldValue(name, newValue);
                              touched.tags = newValue?.length;
                            }}
                            creatable
                            className={
                              touched.tags
                                ? "border-1 border border-blue-menu"
                                : "border-1 border border-gray-300"
                            }
                            onBlur={() => {
                              if (!values.tags?.length) {
                                setFieldTouched("tags", false);
                              } else {
                                setFieldTouched("tags", true);
                              }
                            }}
                          />
                        )}
                      </Field>
                    </div>
                  </div>
                  <div className="relative flex md:justify-end">
                    <Field
                      as={FormTextField}
                      name="linkedin"
                      label="Linkedin"
                      placeholder="https://www.linkedin.com/in/"
                      errors={errors.linkedin}
                      touched={touched.linkedin}
                      onBlur={() => {
                        if (!values.linkedin) {
                          setFieldTouched("linkedin", false);
                        } else {
                          setFieldTouched("linkedin", true);
                        }
                      }}
                    />
                  </div>
                  <div className="w-full md:w-11/12">
                    <div className="md:-mr-7">
                      <Field>
                        {({ form: { errors, touched } }: FieldProps) => {
                          return (
                            <FormCompanySelectField
                              id="contact-form-past-organizations-field"
                              name="past_organizations"
                              label="Past Companies"
                              placeholder={"Select Company"}
                              errors={errors.past_organizations}
                              touched={Boolean(touched.past_organizations)}
                              flexible={true}
                              required={false}
                              withNinja={true}
                              isMulti={true}
                              customClass={"w-full focus:border-blue-menu"}
                              onChange={(companies: any) => {
                                setValues({
                                  ...values,
                                  past_organizations: companies ?? [],
                                });
                                touched.past_organizations = companies?.length;
                              }}
                              onBlur={() => {
                                if (!values.past_organizations?.length) {
                                  setFieldTouched("past_organizations", false);
                                } else {
                                  setFieldTouched("past_organizations", true);
                                }
                              }}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>

                  <div className="relative flex md:justify-end">
                    <Field name="image">
                      {({ form: { setFieldValue } }: FieldProps<File[]>) => (
                        <div className="grid w-full md:w-11/12">
                          <Dropzone
                            accept={{
                              "image/jpeg": [".jpg", ".jpeg"],
                              "image/png": [".png"],
                            }}
                            maxFiles={1}
                            onDrop={(acceptedFiles) => {
                              // do nothing if no files
                              if (acceptedFiles.length === 0) {
                                return;
                              }
                              setFieldValue("image", acceptedFiles[0]);
                              setPhotoFromUrl(false);
                            }}
                          >
                            {({
                              isDragActive,
                              isDragReject,
                              getRootProps,
                              getInputProps,
                            }) => {
                              if (isDragActive) {
                                return (
                                  <div
                                    className="border-grey-500 flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed border-sky-200 bg-blue-50 p-8"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="text-center">
                                      <h3 className="text-xs font-semibold text-blue-menu">
                                        Drop in this file!
                                      </h3>
                                    </div>
                                  </div>
                                );
                              }

                              if (isDragReject) {
                                return (
                                  <div
                                    className="flex h-64 w-full flex-col items-center justify-evenly rounded-3xl border-2 border-dashed border-red-500 p-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <XCircleIcon className="h-10 w-10 fill-red-500" />
                                    <div className="text-center">
                                      <h3 className="text-lg font-semibold text-blue-menu">
                                        This file is not an image!
                                      </h3>
                                      <p className="text-xs font-light">
                                        Supported Formats: PNG, JPEG
                                      </p>
                                    </div>
                                  </div>
                                );
                              }
                              return (
                                <div
                                  className="border-grey-500 flex cursor-pointer flex-col items-center justify-evenly rounded-md border-2 border-dashed p-2"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="flex items-center space-x-2 text-center">
                                    <div className="flex h-12 w-12 items-center justify-center rounded-full bg-blue-menu">
                                      {updatePhoto(values)}
                                    </div>
                                    <div className="text-xs font-semibold text-gray-700">
                                      Drag and Drop Person Image
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="relative">
                  <NewModal title={modalTitle} open={open} onClose={setOpen}>
                    <ErrorMessage error={error} setErrors={setErrors} />
                  </NewModal>
                  <div></div>
                  <div></div>
                  <div className="mt-6 flex justify-end gap-x-3">
                    {!contact && (
                      <div>
                        <button
                          type="button"
                          className="btn-secondary"
                          onClick={() => {
                            setIsClearForm(true);
                            setPreviousFormData(null);
                            resetForm({ values: emptyForm });
                            if (
                              sessionStorage.getItem(CREATE_CONTACT_FORM_KEY)
                            ) {
                              sessionStorage.removeItem(
                                CREATE_CONTACT_FORM_KEY,
                              );
                            }
                            setApolloData(null);
                            setFillApolloData(null);
                            if (touched) {
                              const newTouched: any = {};
                              Object.keys(touched).forEach((key: string) => {
                                newTouched[key] = false;
                              });
                              setTouched(newTouched);
                            }
                          }}
                        >
                          Clear Form
                        </button>
                      </div>
                    )}
                    <div>
                      <button
                        type="submit"
                        className="btn-primary"
                        onClick={() => setSubmit(true)}
                      >
                        {isSubmitting ? (
                          <div className="flex flex-row items-center gap-x-2 text-white">
                            <div>
                              <Spinner className="text-blue h-4 w-4" />
                            </div>
                            <div>Submitting</div>
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <ErrorModal
                  open={openModalError}
                  setOpen={setOpenModalError}
                  errorMessage={errorMessage}
                />
                <NewModal
                  open={openApollo}
                  title={
                    isApolloLoading
                      ? "Searching..."
                      : apolloModalMessage === ""
                        ? "Here's who we found!"
                        : "Error"
                  }
                  onClose={() => setOpenApollo(false)}
                  expanded={false}
                >
                  {apolloData ? (
                    <ApolloSuggestion
                      apolloData={apolloData}
                      handleClickFill={async (
                        apolloData: ApolloPersonDetail,
                      ) => {
                        if (
                          apolloData.image_url &&
                          apolloData.image_url.length > 0 &&
                          !apolloData.image_url.includes("static")
                        ) {
                          await downloadImageAndConvertToFile(
                            apolloData.image_url,
                          )
                            .then((resp) => {
                              apolloData.image_file = resp;
                              setFillApolloData(apolloData);
                            })
                            .catch((err) => {
                              setFillApolloData(apolloData);
                              console.log(err);
                            });
                        }
                        if (values.organization) {
                          setFieldValue("organization", values.organization);
                          apolloData.organization = values.organization;
                        } else if (
                          apolloData.organization_name &&
                          apolloData.organization_name.length > 0
                        ) {
                          await autocompleteOrganizations(
                            apolloData.organization_name,
                          ).then(async (resp) => {
                            if (resp.length > 0) {
                              setFieldValue("organization", resp[0]);
                              apolloData.organization = resp[0];
                            } else {
                              await autocompleteNinjaOrganizations(
                                apolloData.organization_name!,
                              ).then((resp) => {
                                if (resp.length > 0) {
                                  setFieldValue("organization", resp[0]);
                                  apolloData.organization = resp[0];
                                }
                              });
                            }
                          });
                        }
                        setFillApolloData(apolloData);
                        setPhotoFromUrl(true);
                        setOpenApollo(false);
                      }}
                    />
                  ) : (
                    <div className="text-xs text-gray-600">
                      {apolloModalMessage}
                    </div>
                  )}
                </NewModal>
              </Form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ContactForm;
