import { ErrorMessage, ErrorMessageProps, Field, FieldProps } from "formik";
import * as types from "../api/types";
import { Visibility } from "../api/types";
import GroupSelectAll from "./Select/GroupSelectAll";
import Tooltip from "./Tooltip";
import UserSelect from "./Select/UserSelect";
import { classNames } from "./utils";

type VisibilityFieldProps = {
  visible: Visibility;
  legend?: string;
  visibilityOptions?: { value: Visibility; label: string }[];
  defaultOptions?: types.GroupAutocompleteResult[];
  hideLabel?: boolean;
};

export default function VisibilityField({
  visible,
  legend,
  visibilityOptions = [
    { value: "public", label: "All users" },
    { value: "groups", label: "Specific groups/users" },
    { value: "private", label: "Only me" },
  ],
  defaultOptions = [],
  hideLabel = false,
}: VisibilityFieldProps) {
  interface FieldErrorProps extends ErrorMessageProps {}

  const FieldError = ({ name }: FieldErrorProps) => (
    <ErrorMessage name={name}>
      {(error) => <div className="mt-1 text-red-500">{error}</div>}
    </ErrorMessage>
  );

  return (
    <>
      <fieldset className="contents">
        <legend
          className={`${legend ? legend : "mb-1 text-xs font-medium"} w-20 ${
            hideLabel ? "hidden" : ""
          }`}
        >
          <div className="mb-3 mt-2 flex w-full flex-row items-center font-semibold">
            Visibility
            <Tooltip
              title="What is Visibility?"
              content="Visibility allows you to have an option of deciding who can view the activity. All user activity by default is only visible to user groups or funds that you belong to."
            />
          </div>
        </legend>
        <div>
          <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-6">
            {visibilityOptions.map((visibilityType) => (
              <div key={visibilityType.value} className="flex items-center">
                <Field
                  id={`visibilityField-${visibilityType.value}`}
                  name="visibility"
                  type="radio"
                  value={visibilityType.value}
                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
                <label
                  htmlFor={`visibilityField-${visibilityType.value}`}
                  className="font-xs ml-3 block text-xs text-gray-700"
                >
                  {visibilityType.label}
                </label>
              </div>
            ))}
          </div>
          <FieldError name="visibility" />
        </div>
      </fieldset>
      <div
        className={classNames(
          "flex contents flex-row",
          visible !== "groups" && "hidden",
        )}
      >
        <div className="mb-2 h-full w-48">
          <label className="mt-2.5 flex w-60" htmlFor="groupsField">
            <div className="text-xs font-medium">Specific groups/users</div>
            <div className="h-4 w-4 text-red-600">*</div>
          </label>
        </div>
        <div className="flex flex-col">
          <div className="max-w-md text-xs">
            <Field name="groups">
              {({ field, form: { setFieldValue } }: FieldProps) => (
                <GroupSelectAll
                  id="groupsField"
                  {...field}
                  onChange={(newValue: any) => {
                    setFieldValue(field.name, newValue);
                  }}
                  isMulti
                  placeholder="Select Group"
                />
              )}
            </Field>
            <FieldError name="groups" />
          </div>
          <div className="mt-3 max-w-md text-xs">
            <Field name="users">
              {({ field, form: { setFieldValue } }: FieldProps) => (
                <UserSelect
                  id="usersField"
                  {...field}
                  onChange={(newValue) => {
                    setFieldValue(field.name, newValue);
                  }}
                  isMulti
                  placeholder="Select User"
                />
              )}
            </Field>
            <FieldError name="users" />
          </div>
        </div>
      </div>
    </>
  );
}
