import { useEffect, useState } from "react";
import useSWR from "swr";
import { GroupAutocompleteResult, Visibility } from "../api/types";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";
import VisibilityField from "./VisibilityField";

type VisibilityFieldCustomProps = {
  values: Visibility;
  hideLabel?: boolean;
};
export default function VisibilityFieldCustom({
  values,
  hideLabel = false,
}: VisibilityFieldCustomProps) {
  const { data: initialValues } = useSWR<any>(GLOBAL_GROUP_KEY);

  const [selectedGroup, setSelectedGroup] =
    useState<GroupAutocompleteResult[]>();

  useEffect(() => {
    if (initialValues) {
      setSelectedGroup(initialValues);
    }
  }, [initialValues, setSelectedGroup]);

  return (
    <VisibilityField
      visible={values}
      defaultOptions={selectedGroup}
      legend="font-medium text-xs mr-16"
      hideLabel={hideLabel}
    />
  );
}
