import { UserIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { classNames } from "./utils";

type ThumbnailProps = {
  file: File;
  className: string;
};
export const Thumbnail = ({ file, className }: ThumbnailProps) => {
  const [loading, setLoading] = useState(false);
  const [thumb, setThumb] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    if (!file) {
      return;
    }
    let reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result) {
        return;
      }
      setLoading(false);
      setThumb(reader.result.toString());
    };
    reader.readAsDataURL(file);
  }, [file]);

  if (loading || !thumb) {
    return <UserIcon className={className} />;
  }

  return (
    <img
      className={classNames("object-contain", className)}
      src={thumb}
      alt={file.name}
    />
  );
};

export default Thumbnail;
