type SpinnerProps = {
  className?: string;
};

export const Spinner = ({ className }: SpinnerProps) => (
  <div
    className={`inline-block animate-spin ${
      className ?? "h-5 w-5"
    } rounded-full border-[3px] border-current border-t-transparent`}
    role="status"
    aria-label="loading"
  ></div>
);
export default Spinner;
