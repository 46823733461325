import { Popover, Transition } from "@headlessui/react";
import {
  BookmarkIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { AxiosError } from "axios";
import { Fragment, useEffect, useRef, useState } from "react";
import * as api from "../../api/index";
import { AgentPersona, AgentPersonaStub } from "../../api/types";
import { ConfirmationModal } from "../ConfirmationModal";
import ErrorModal from "../ErrorModal";
import NewModal from "../NewModal";
import { parseAxiosError } from "../utils";
import { ShowSnackBar } from "../Utils/supportMessage";
import { AgentForm } from "./AgentForm";

type AgentsViewProps = {
  currentAgent: AgentPersonaStub | undefined;
  onItemChanged: (agent: AgentPersonaStub) => void;
  onDeleteItem: () => void;
  showAskVal: boolean;
};

export const AgentsView = ({
  currentAgent,
  onItemChanged,
  onDeleteItem,
  showAskVal,
}: AgentsViewProps) => {
  const [agents, setAgents] = useState<AgentPersonaStub[]>([]);
  const [showAgentForm, setShowAgentForm] = useState(false);

  const [agentDetail, setAgentDetail] = useState<AgentPersona>();

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const [showConfirmDeletionAgent, setShowConfirmDeletionAgent] =
    useState<boolean>(false);
  const [deletingAgent, setDeletingAgent] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);

  const popoverRefs = useRef<any>([]);

  const getAllAgents = () => {
    api
      .getAllAgentPersona()
      .then((response: any) => {
        if (response.data) {
          setAgents(response.data.data);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
      });
  };

  useEffect(() => {
    if (showAskVal) {
      getAllAgents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAskVal]);

  const deleteAgent = (agentId: number) => {
    setDeletingAgent(true);
    api
      .deleteAgentPersona(agentId)
      .then((response: any) => {
        if (response.status === 200) {
          setAgents(agents.filter((agent) => agent.id !== agentId));
          setDeletingAgent(false);
          onDeleteItem();
          ShowSnackBar("You deleted this agent.");
          setShowConfirmDeletionAgent(false);
        }
      })
      .catch((error: AxiosError) => {
        setErrorMessage(parseAxiosError(error));
        setShowErrorModal(true);
        setDeletingAgent(false);
      });
  };

  const loadAgent = (agentId: number) => {
    if (showAskVal) {
      api
        .getAgentPersonaById(agentId)
        .then((response: any) => {
          if (response.data) {
            setAgentDetail(response.data.data);
            setShowAgentForm(true);
          }
        })
        .catch((error: AxiosError) => {
          setErrorMessage(parseAxiosError(error));
          setShowErrorModal(true);
        });
    }
  };

  const favoriteAgent = (agent: AgentPersonaStub) => {
    api.pinAgent(agent.id, !agent.pinned).then((response) => {
      if (response.data) {
        getAllAgents();
      }
    });
  };

  return (
    <div className={`mx-2 flex h-full flex-col py-3`}>
      <div className="flex flex-row items-center gap-x-2 pl-1">
        <div className="text-sm font-semibold">Agents</div>
        <div className={`group relative`}>
          <PlusIcon
            className="h-4 w-4 cursor-pointer"
            onClick={() => {
              setAgentDetail(undefined);
              setShowAgentForm(true);
            }}
          />
          <div
            id="tooltip-bottom"
            role="tooltip"
            className="tooltip invisible absolute left-0 top-5 z-top inline-block w-max rounded-lg bg-gray-900 py-1 px-2 text-3xs font-medium text-white shadow-sm group-hover:visible"
          >
            Create New Agent
          </div>
        </div>
      </div>
      <div className="mt-2 flex h-full flex-col gap-y-1 overflow-y-auto text-2xs">
        {agents.map((agent: AgentPersonaStub) => (
          <div
            key={agent.id}
            className={`group flex h-6 flex-row items-center justify-between rounded hover:cursor-pointer hover:bg-gray-100 hover:pl-0.5 hover:text-black ${
              currentAgent?.id === agent.id && "bg-blue-100 text-blue-900"
            }`}
            onMouseLeave={() => {
              agents.forEach((item) => {
                if (
                  popoverRefs.current[item.id] &&
                  popoverRefs.current[item.id].ariaExpanded === "true"
                ) {
                  popoverRefs.current[item.id].click();
                }
              });
            }}
          >
            <div
              id={`agent-${agent.id}`}
              className={`h-[23px] w-full break-all p-1 line-clamp-1 ${
                currentAgent?.id === agent.id && "font-semibold"
              }`}
              onClick={() => onItemChanged(agent)}
            >
              {agent.name}
            </div>
            {!agent.default_val && (
              <div className="flex flex-row items-center">
                {agent.pinned && (
                  <div>
                    <BookmarkIcon
                      className={`h-4 w-4 cursor-pointer stroke-gray-500 ${
                        agent.pinned && "fill-blue-900"
                      }`}
                      onClick={(event) => {
                        favoriteAgent(agent);
                      }}
                    />
                  </div>
                )}
                <div className="h-5 w-5 opacity-0 group-hover:opacity-100">
                  <Popover className="relative">
                    {({ close }) => (
                      <>
                        <Popover.Button
                          ref={(ref: any) => {
                            popoverRefs.current[agent.id] = ref;
                          }}
                        >
                          <div>
                            <EllipsisVerticalIcon
                              className={`grey-500 h-5 w-5`}
                            />
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute -left-[75px] -top-2 z-top w-fit rounded-lg border border-gray-200 bg-white p-2">
                            <div className="flex flex-row gap-x-1">
                              <div>
                                <BookmarkIcon
                                  className={`h-4 w-4 cursor-pointer stroke-gray-500 ${
                                    agent.pinned && "fill-blue-900"
                                  }`}
                                  onClick={(event) => {
                                    favoriteAgent(agent);
                                    close();
                                  }}
                                />
                              </div>
                              <div
                                className="h-4 w-4 cursor-pointer"
                                onClick={() => {
                                  loadAgent(agent.id);
                                  close();
                                }}
                              >
                                <PencilIcon className="h-4 w-4 stroke-gray-500 hover:stroke-blue-menu" />
                              </div>
                              <div
                                className="h-4 w-4 cursor-pointer"
                                onClick={() => {
                                  setDeleteId(agent.id);
                                  setShowConfirmDeletionAgent(true);
                                  close();
                                }}
                              >
                                <TrashIcon className="h-4 w-4 stroke-gray-500 hover:stroke-red-500" />
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <NewModal
        title={agentDetail ? "Edit agent" : "Create agent"}
        customClass="!overflow-visible"
        open={showAgentForm}
        onClose={setShowAgentForm}
      >
        <AgentForm
          agent={agentDetail}
          onSuccess={(agent, isCreateNew) => {
            if (agentDetail && !isCreateNew) {
              agents.forEach((item, index) => {
                if (item.id === agentDetail.id) {
                  agents[index] = agent;
                  return false;
                }
              });
              setAgents(agents);
            } else {
              setAgents([...agents, agent]);
            }
            onItemChanged(agent);
            setAgentDetail(undefined);
            setShowAgentForm(false);
          }}
        />
      </NewModal>
      <ErrorModal
        open={showErrorModal}
        setOpen={setShowErrorModal}
        errorMessage={errorMessage}
      />
      <ConfirmationModal
        open={showConfirmDeletionAgent}
        title="Delete agent?"
        subtitle="This action cannot be undone"
        processing={deletingAgent}
        processingText={"Deleting..."}
        submitButtonText={"Delete"}
        onClose={() => {
          setDeleteId(0);
          setShowConfirmDeletionAgent(false);
        }}
        onSubmit={() => deleteAgent(deleteId)}
      />
    </div>
  );
};
