import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowSmallLeftIcon,
  Bars3Icon,
  MagnifyingGlassIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment, PropsWithChildren, useState } from "react";
import useSWR from "swr";
import CreateDropDownMenu from "./CreateDropDownMenu";
import QuickSearch from "./QuickSearch";
import MobileSidebar from "./SidebarMobileView";
import { setNavigation } from "./Utils/commons";
import { GLOBAL_GROUP_KEY } from "./Utils/constant";

export type GlobalGroupProps = {
  label: string;
  value: string;
};

const Layout = ({ children }: PropsWithChildren<{}>) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openQuickSearch, setOpenQuickSearch] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);

  const router = useRouter();
  const envString = `${process.env.NEXT_PUBLIC_LABS_INSTANCE}` || "default";

  const { data: selectedGroup } = useSWR<GlobalGroupProps>(GLOBAL_GROUP_KEY);

  var updatedNavigation = setNavigation("default");

  if (selectedGroup) {
    updatedNavigation = setNavigation(selectedGroup.label);
  }

  const current = updatedNavigation.find(
    (item) =>
      item?.href === router.pathname ||
      item.prefixes?.includes(router.pathname) ||
      (item.query && router.asPath.includes(item.query)),
  );

  const currentUrl = router.asPath.slice(1).split("?")[0];

  function cleanURL(currentURL: string): { formatted: string } {
    const parts = currentURL.split("/");

    const capitalizeAndClean = (str: string) => {
      return str
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
        )
        .join(" ");
    };

    if (parts.length === 1) {
      return { formatted: capitalizeAndClean(parts[0]) };
    } else if (parts.length === 2) {
      if (!isNaN(Number(parts[1]))) {
        return { formatted: `${capitalizeAndClean(parts[0])} (${parts[1]})` };
      } else {
        return {
          formatted: `${capitalizeAndClean(parts[0])} ${capitalizeAndClean(
            parts[1],
          )}`,
        };
      }
    } else {
      return { formatted: "Unknown URL format" };
    }
  }

  const menuText = (text: string) => {
    switch (text) {
      case "People & Organizations":
        return "Connections";
      case "Opportunities":
        return "Opportunities";
      case "Vertex Platform":
        return "VPCs";
      default:
        return text;
    }
  };

  return (
    <>
      <div className="flex h-full">
        <Transition show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute -right-[13px] top-[30px] flex h-7 w-7 flex-col items-center justify-center rounded-full bg-white drop-shadow-lg">
                    <ChevronLeftIcon
                      className="h-5 w-5 cursor-pointer"
                      onClick={() => setSidebarOpen(false)}
                      aria-hidden="true"
                    />
                  </div>
                </Transition.Child>
                <MobileSidebar closeSidebar={() => setSidebarOpen(false)} />
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition>

        <div className="fixed inset-y-0 left-0 right-0 flex h-full flex-1 flex-col overflow-y-auto overflow-x-hidden md:flex-row">
          <div className="sticky top-0 z-top bg-gray-100 md:hidden">
            <div className="flex h-14 items-center justify-between bg-blue-menu pl-4">
              <div
                className={`flex flex-row items-center space-x-2 ${
                  openQuickSearch ? "invisible" : "visible"
                }`}
              >
                <button
                  type="button"
                  className="inline-flex h-8 w-8 items-center justify-center rounded-md text-gray-500 hover:text-gray-900"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <Bars3Icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
                <button type="button" onClick={() => router.back()}>
                  <ArrowSmallLeftIcon className="h-6 w-6 text-white" />
                </button>
                <div
                  className={`flex flex-col text-sm text-white ${
                    openQuickSearch ? "invisible" : "visible"
                  }`}
                >
                  {/* {title} */}
                  {cleanURL(currentUrl).formatted}
                </div>
              </div>
              <div
                className={`flex flex-row items-center space-x-2 pr-4 ${
                  openQuickSearch ? "absolute right-0 z-[101]" : ""
                }`}
              >
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setOpenCreate(false);
                      setOpenQuickSearch(!openQuickSearch);
                    }}
                  >
                    {!openQuickSearch ? (
                      <MagnifyingGlassIcon className="h-5 w-5 text-white" />
                    ) : (
                      <XMarkIcon className="h-5 w-5 text-white" />
                    )}
                  </button>
                </div>
                <div>
                  <button
                    id="create-dropdown-menu-mobile-plus-button"
                    type="button"
                    onClick={() => {
                      setOpenQuickSearch(false);
                      setOpenCreate(!openCreate);
                    }}
                  >
                    <PlusIcon className="h-5 w-5 text-white" />
                  </button>
                </div>
              </div>
              <div
                className={`absolute top-0 right-0 left-0 z-top flex h-full w-10/12 w-full items-center pl-6 pr-6 duration-300 lg:invisible ${
                  openQuickSearch === true
                    ? "visible translate-x-0"
                    : "invisible translate-x-full"
                }`}
              >
                {openQuickSearch && <QuickSearch isMobile={true} />}
              </div>
              <CreateDropDownMenu
                showInHeader={true}
                openCreate={openCreate}
                setOpenCreate={setOpenCreate}
              />
            </div>
          </div>
          <div className="fixed left-0 flex hidden bg-blue-menu shadow-lg md:block">
            <div className={`relative h-screen w-[80px]`}>
              <div className="no-scrollbar mt-2.5 flex h-full flex-col justify-between overflow-x-auto">
                <div className={`items-left flex flex-col`}>
                  <div className="flex flex-row">
                    <img
                      src="/labs-icon.png"
                      alt="Labs logo"
                      className="ml-4 mt-5 mb-2 h-[30px] w-[50px] cursor-pointer"
                      onClick={() =>
                        router.push(
                          envString === "vvseai" || envString === "vvhc"
                            ? "/dealflow"
                            : "/",
                        )
                      }
                    />
                  </div>
                  {updatedNavigation.map((item: any) =>
                    item ? (
                      <div key={item.key} className={`my-1 duration-300`}>
                        <Link
                          href={item.href}
                          key={item.key}
                          title={item.name}
                          onClick={() => {
                            if (
                              item.key === current?.key &&
                              item.key === "chatbot"
                            ) {
                              router.reload();
                            } else {
                              router.push(item.href, undefined, {
                                shallow: true,
                              });
                            }
                          }}
                          className={`flex h-full w-full flex-col items-center text-sm font-light tracking-wide ${
                            item.key === current?.key
                              ? "border-blue-menu font-medium text-blue-menu"
                              : `border-transparent font-light text-gray-300`
                          }`}
                        >
                          <div
                            className={`mb-1 flex h-10 w-10 flex-col items-center justify-center duration-300 ${
                              item.key === current?.key
                                ? "rounded-full bg-white"
                                : "hover:rounded-full hover:bg-white/50 hover:text-black"
                            }`}
                          >
                            <item.icon
                              className={`h-6 w-6 flex-shrink-0`}
                              aria-hidden="true"
                            />
                          </div>
                          <div
                            className={`origin-left text-center text-2.5xs text-white ${
                              item.key === current?.key
                                ? "font-semibold"
                                : "font-normal"
                            }`}
                          >
                            {menuText(item.name)}
                          </div>
                        </Link>
                      </div>
                    ) : null,
                  )}
                </div>
              </div>
            </div>
          </div>

          <main
            className={`ml-0 h-full w-full overflow-y-auto duration-300 md:ml-[88px] md:overflow-x-hidden`}
            id="sidebar-wide-view"
          >
            {children}
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
