import { Combobox, Tab } from "@headlessui/react";
import {
  ArrowTopRightOnSquareIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import Link from "next/link";
import { useRouter } from "next/router";
import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useSWR from "swr";
import { UrlObject } from "url";
import { useDebounce } from "use-debounce";
import { getter, updateSearchHistory } from "../api";
import * as types from "../api/types";
import { Success } from "../api/types";
import Avatar from "./Avatar";
import { ColoredTag, generateColoredTagMappings } from "./ColoredTags";
import { useOpportunity } from "./Hook/useOpportunity";
import { getOpportunityType } from "./Opportunity/OpportunityUtils";
import QuickSearchSkeleton from "./Skeletons/QuickSearchSkeleton";
import { FunnelStageColor } from "./Utils/commons";
import { classNames } from "./utils";

function searchHistoryKey(item: types.SearchHistory) {
  switch (item.type) {
    case "person":
      return `people/${item.person.id}`;
    case "text":
      return `text/${item.text}`;
    case "organization":
      return `organizations/${item.organization.id}`;
    case "opportunity":
      return `opportunity/${item.opportunity.id}`;
    case "meeting_note":
      return `meeting_notes/${item.meeting_note.id}`;
  }
}

type SearchHistoryResultProps = {
  history: types.SearchHistory;
  opportunityTypes: types.OpportunityType[] | [];
};
const SearchHistoryResult = ({
  history,
  opportunityTypes,
}: SearchHistoryResultProps) => {
  switch (history.type) {
    case "person":
      return <PersonSearchResult person={history.person} />;
    case "organization":
      return <OrganizationSearchResult organization={history.organization} />;
    case "opportunity":
      return (
        <OpportunitySearchResult
          opportunity={history.opportunity}
          opportunityTypes={opportunityTypes || []}
        />
      );
    case "meeting_note":
      return <MeetingNoteSearchResult meeting_note={history.meeting_note} />;
    case "text":
      return <></>;
  }
};

type PersonSearchResultProps = {
  person: types.SearchResultPerson;
};
const PersonSearchResult: React.FC<PersonSearchResultProps> = ({ person }) => {
  const { hideResult } = React.useContext(QuickSearchContext);
  return (
    <Link
      href={`/people/${person.id}`}
      onClick={() => {
        updateSearchHistory({
          id: person.id,
          type: "person",
        });
        hideResult();
      }}
    >
      <div className="flex items-center pl-1 pr-2 text-gray-600 hover:cursor-pointer hover:rounded-lg hover:bg-blue-100 hover:text-black">
        <Avatar
          name={person.name}
          size={28}
          className="shrink-0"
          url={person.image_url}
        />
        <div className="break-word-custom ml-2 grid text-2xs">
          <span className="font-semibold">{person.name}</span>
          <span className="font-light">{person.organization}</span>
        </div>
      </div>
    </Link>
  );
};

type ResultGroupProps = {
  title: string;
  data?: types.SearchResults;
  children: (data: types.SearchResults) => React.ReactNode[];
  externalLink?: string | UrlObject;
  isMobile?: boolean;
};
const ResultGroupOptions = ({
  data,
  children,
}: Pick<ResultGroupProps, "data" | "children">) => {
  if (!data) {
    return <QuickSearchSkeleton />;
  }
  const results = children(data);
  if (results.length === 0) {
    return <div className="text-xs text-gray-600">No results</div>;
  }
  return <div className="grid grid-cols-1 gap-y-3">{results}</div>;
};

const ResultGroup = ({
  title,
  data,
  children,
  externalLink,
  isMobile = false,
}: ResultGroupProps) => {
  return (
    <div className="mt-3 grid grid-cols-1 gap-y-2">
      {!isMobile && (
        <>
          <div className="flex items-center justify-between">
            <div className="text-xs text-gray-500">{title}</div>
            {externalLink && (
              <Link href={externalLink}>
                <ArrowTopRightOnSquareIcon className="h-4 w-4" />
              </Link>
            )}
          </div>
          <div className="border-1 border"></div>
        </>
      )}

      <ResultGroupOptions data={data}>{children}</ResultGroupOptions>
    </div>
  );
};

type OrganizationSearchResultProps = {
  organization: types.SearchResultOrganization;
};
const OrganizationSearchResult: React.FC<OrganizationSearchResultProps> = ({
  organization,
}) => {
  const { hideResult } = React.useContext(QuickSearchContext);
  return (
    <Link
      href={`/organizations/${organization.id}`}
      onClick={() => {
        updateSearchHistory({
          id: organization.id,
          type: "organization",
        });
        hideResult();
      }}
    >
      <div className="flex items-center gap-x-2 pl-1 pr-1 text-gray-600 hover:cursor-pointer hover:rounded-lg hover:bg-blue-100 hover:text-black">
        <Avatar
          name={organization.name}
          url={organization.logo_url}
          size={28}
          className="shrink-0 rounded-none"
        />
        <div className="flex w-full flex-1 flex-col items-start text-2xs">
          <div className="flex w-full flex-row justify-start">
            <div className="flex w-full flex-row justify-between">
              <span>
                <b className="font-semibold">{organization.name}</b>{" "}
                <i className="">
                  {` . ${organization.domain ? organization.domain : ""}`}
                </i>
                {organization.country?.label
                  ? ` . ${organization.country.label}`
                  : ""}
              </span>
              {organization.is_vpc && (
                <img
                  src="/VertexRoundIcon.svg"
                  alt="VertexRoundIcon"
                  className="ml-2 mt-0.5 inline h-3"
                ></img>
              )}
            </div>
          </div>
          <div
            className="w-full text-2xs line-clamp-2"
            style={{ overflowWrap: "anywhere" }}
          >
            {organization.description}
          </div>
        </div>
      </div>
    </Link>
  );
};

type NinjaOrganizationSearchResultProps = {
  ninja_organization: types.SearchResultNinjaOrganization;
};
const NinjaOrganizationSearchResult: React.FC<
  NinjaOrganizationSearchResultProps
> = ({ ninja_organization }) => {
  const { hideResult } = React.useContext(QuickSearchContext);
  return (
    <Link
      href={`/organizations/${ninja_organization.uuid}`}
      onClick={hideResult}
    >
      <div className="flex items-center pl-1 pr-1 text-gray-600 hover:cursor-pointer hover:rounded-lg hover:bg-blue-100 hover:text-black">
        <Avatar
          name={ninja_organization.name}
          size={28}
          url={ninja_organization.logo_url}
          className="shrink-0 rounded-none"
        />
        <div className="ml-2 flex w-full flex-1 flex-col items-start text-2xs">
          <div className="flex w-full flex-row justify-start">
            <div className="flex flex-row">
              <span>
                <b className="font-semibold">{ninja_organization.name}</b>{" "}
                {` . ${
                  ninja_organization.domain ? ninja_organization.domain : ""
                } `}
                {ninja_organization.country?.label
                  ? ` . ${ninja_organization.country.label}`
                  : ""}
              </span>
            </div>
          </div>
          <div
            className="w-full text-2xs line-clamp-2"
            style={{ overflowWrap: "anywhere" }}
          >
            {ninja_organization.description}
          </div>
        </div>
      </div>
    </Link>
  );
};

type MeetingNoteSearchResultProps = {
  meeting_note: types.SearchResultMeetingNote;
};
const MeetingNoteSearchResult: React.FC<MeetingNoteSearchResultProps> = ({
  meeting_note,
}) => {
  const { hideResult } = React.useContext(QuickSearchContext);
  return (
    <Link
      href={`/meeting_notes/${meeting_note.id}`}
      onClick={() => {
        updateSearchHistory({
          id: meeting_note.id,
          type: "meeting_note",
        });
        hideResult();
      }}
    >
      <div className="flex items-center pl-1 pr-1 text-gray-600 hover:cursor-pointer hover:rounded-lg hover:bg-blue-100 hover:text-black">
        <div className="break-word-custom flex w-full justify-between text-2xs">
          <span className="mr-2">{meeting_note.title}</span>
          <Avatar
            name={meeting_note.owner?.name}
            size={26}
            className="shrink-0"
            url={meeting_note.owner?.image_url}
          />
        </div>
      </div>
    </Link>
  );
};

type OpportunitySearchResultProps = {
  opportunity: types.SearchResultOpportunity;
  opportunityTypes: types.OpportunityType[] | [];
};
const OpportunitySearchResult: React.FC<OpportunitySearchResultProps> = ({
  opportunity,
  opportunityTypes,
}) => {
  const { hideResult } = React.useContext(QuickSearchContext);
  const opportunityType = getOpportunityType(
    opportunityTypes,
    opportunity.opportunity_type,
  );

  const buildDescription = (description: string) => {
    if (description.length <= 84) {
      return description;
    }
    return description.substring(0, 80).concat("...");
  };

  return (
    <Link
      href={`/opportunity/${opportunity.id}`}
      onClick={() => {
        updateSearchHistory({
          id: opportunity.id,
          type: "opportunity",
        });
        hideResult();
      }}
    >
      <div className="flex h-full items-center pr-1 text-gray-600 hover:cursor-pointer hover:rounded-lg hover:bg-blue-100 hover:text-black">
        <div
          className={`mr-2 h-full w-1 rounded-l-lg ${
            opportunity.status === "active"
              ? "bg-green-500"
              : opportunity.status === "closed"
                ? "bg-red-500"
                : opportunity.status === "revisit"
                  ? "bg-yellow-500"
                  : ""
          }`}
        />
        <div className="flex h-full w-full items-center justify-between">
          <div className="break-word-custom mr-2 grid text-2xs">
            <div className="mb-1 flex flex-col">
              <span className="font-semibold">{opportunity.name}</span>
            </div>
            <span className="mb-1 font-light">
              {buildDescription(opportunity.description)}
            </span>
            <div className="flex flex-row flex-wrap items-center justify-start">
              <span className="mr-1 rounded-full bg-gray-200 px-2 py-1 font-light text-black">
                {opportunity.opportunity_type}
              </span>
              <ColoredTag
                mappings={generateColoredTagMappings(
                  opportunityType.funnel.map((item: any) => item.name),
                  FunnelStageColor,
                )}
                value={opportunity.funnel_stage}
                className="w-max font-light"
                isTag={true}
              />
            </div>
          </div>
          <div className="flex h-full shrink-0 flex-col pt-1">
            <Avatar
              name={opportunity.owner?.name}
              size={26}
              url={opportunity.owner?.image_url}
              className="shrink-0"
            />
          </div>
        </div>
      </div>
    </Link>
  );
};

interface SearchHistoryProps {
  showHistory: boolean;
  opportunityTypes: types.OpportunityType[] | [];
}

const SearchHistory = ({
  showHistory = false,
  opportunityTypes,
}: SearchHistoryProps) => {
  const { data } = useSWR<Success<types.SearchHistory[]>>(
    showHistory ? "/api/people_map/search_history" : null,
    getter,
    {
      revalidateOnFocus: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    },
  );

  if (!data) {
    return <></>;
  }

  return (
    <Combobox.Options className="absolute mt-2 grid w-full max-w-sm grid-cols-1 gap-y-3 rounded-xl bg-white px-3 py-4 shadow-md">
      <div className="mt-3 flex items-center justify-between">
        <div className="text-xs text-gray-500 lg:text-sm">Search History</div>
      </div>
      {data.data.map((item) => (
        <li key={searchHistoryKey(item)}>
          <SearchHistoryResult
            history={item}
            opportunityTypes={opportunityTypes}
          />
        </li>
      ))}
    </Combobox.Options>
  );
};

interface SearchResultsProps {
  personData: types.SearchResultPerson[] | undefined;
  organizationData: types.SearchResultOrganization[] | undefined;
  ninjaOrganizationData: types.SearchResultNinjaOrganization[] | undefined;
  meetingnoteData: types.SearchResultMeetingNote[] | undefined;
  opportunityData: types.SearchResultOpportunity[] | undefined;
  query: string;
  opportunityTypes: types.OpportunityType[] | [];
  isMobile?: boolean;
  open: boolean;
}

const SearchResults = ({
  personData,
  organizationData,
  ninjaOrganizationData,
  meetingnoteData,
  opportunityData,
  query,
  opportunityTypes,
  isMobile,
  open,
}: SearchResultsProps) => {
  const [index, setIndex] = useState(0);
  const tabHeaders = useMemo(() => {
    return [
      {
        index: 0,
        name: "Opportunities",
      },
      {
        index: 1,
        name: "Organizations",
      },
      {
        index: 2,
        name: "Notes",
      },
      {
        index: 3,
        name: "People",
      },
    ];
  }, []);
  return (
    <div
      className={`absolute -left-6 mt-2 w-screen overflow-auto rounded-xl bg-white shadow-md md:left-0 md:w-full md:min-w-[700px] lg:min-w-[900px] xl:min-w-[1050px] ${
        isMobile && open ? "h-[94vh]" : ""
      }`}
    >
      <Combobox.Options>
        {/* Desktop mode */}
        <div
          className={`hidden w-full items-start gap-x-6 gap-y-3 p-4 md:grid md:w-[700px] md:grid-cols-2 lg:w-[900px] lg:grid-cols-3 lg:grid-rows-1 xl:w-[1050px] xl:grid-cols-3 xl:grid-rows-1`}
        >
          <div className={`overflow-auto md:col-span-1 md:max-h-full`}>
            <ResultGroup
              title="Opportunities"
              data={opportunityData}
              externalLink={{
                pathname: "/all_opportunity",
                query: { q: query },
              }}
            >
              {(opportunityData) =>
                opportunityData.map((opportunity) => (
                  <Combobox.Option
                    key={(opportunity as types.SearchResultOpportunity).id}
                    value={opportunity}
                  >
                    <OpportunitySearchResult
                      opportunity={opportunity as types.SearchResultOpportunity}
                      opportunityTypes={opportunityTypes}
                    />
                  </Combobox.Option>
                ))
              }
            </ResultGroup>
          </div>
          <div className="md:col-span-1">
            <ResultGroup
              title="Organizations"
              data={organizationData}
              externalLink={{
                pathname: "/discover/organizations",
                query: { q: query },
              }}
            >
              {(organizationData) =>
                organizationData.map((org) => (
                  <Combobox.Option
                    key={(org as types.SearchResultOrganization).id}
                    value={org}
                  >
                    <OrganizationSearchResult
                      organization={org as types.SearchResultOrganization}
                    />
                  </Combobox.Option>
                ))
              }
            </ResultGroup>

            <ResultGroup
              title="More Organizations"
              data={ninjaOrganizationData}
            >
              {(ninjaOrganizationData) =>
                ninjaOrganizationData.map((org) => (
                  <Combobox.Option
                    key={(org as types.SearchResultNinjaOrganization).uuid}
                    value={org}
                  >
                    <NinjaOrganizationSearchResult
                      ninja_organization={
                        org as types.SearchResultNinjaOrganization
                      }
                    />
                  </Combobox.Option>
                ))
              }
            </ResultGroup>
          </div>
          <div
            className={`md:col-span-1 md:row-start-2 lg:col-start-3 lg:row-start-1 xl:col-start-3 xl:row-start-1`}
          >
            <ResultGroup
              title="Notes"
              data={meetingnoteData}
              externalLink={{
                pathname: "meeting_notes",
                query: { q: query },
              }}
            >
              {(meetingnoteData) =>
                meetingnoteData.map((meeting_note) => (
                  <Combobox.Option
                    key={(meeting_note as types.SearchResultMeetingNote).id}
                    value={meeting_note}
                  >
                    <MeetingNoteSearchResult
                      meeting_note={
                        meeting_note as types.SearchResultMeetingNote
                      }
                    />
                  </Combobox.Option>
                ))
              }
            </ResultGroup>
            <ResultGroup
              title="People"
              data={personData}
              externalLink={{
                pathname: "/discover/people",
                query: { q: query },
              }}
            >
              {(personData) =>
                personData.map((p) => (
                  <Combobox.Option
                    key={(p as types.SearchResultPerson).id}
                    value={p}
                  >
                    <PersonSearchResult
                      person={p as types.SearchResultPerson}
                    />
                  </Combobox.Option>
                ))
              }
            </ResultGroup>
          </div>
        </div>
        {/* Mobile mode */}
        <div className="p-4 md:hidden">
          <Tab.Group
            selectedIndex={index}
            onChange={(index) => setIndex(index)}
            defaultIndex={0}
          >
            <div className="my-3 flex flex-row items-end items-center justify-between border-b-[1px] border-gray-300 md:mb-9">
              <Tab.List className="flex gap-x-4 overflow-auto">
                {tabHeaders.map((tabHeader) => (
                  <div key={tabHeader.index} className="group">
                    <Tab
                      className={({ selected }) =>
                        classNames(
                          "whitespace-nowrap px-1 pb-2 pt-3 text-xs font-medium focus:outline-none focus:ring-0",
                          selected || tabHeader.index === index
                            ? "text-blue-menu"
                            : "text-gray-500 group-hover:text-gray-700",
                        )
                      }
                    >
                      {tabHeader.name}
                    </Tab>
                    <div
                      className={`h-1 w-full rounded-t-md ${
                        tabHeader.index === index
                          ? "bg-blue-menu"
                          : "group-hover:bg-gray-300"
                      }`}
                    ></div>
                  </div>
                ))}
              </Tab.List>
            </div>
            <Tab.Panels>
              <Tab.Panel>
                <ResultGroup
                  title="Opportunities"
                  data={opportunityData}
                  isMobile
                  externalLink={{
                    pathname: "/all_opportunity",
                    query: { q: query },
                  }}
                >
                  {(opportunityData) =>
                    opportunityData.map((opportunity) => (
                      <Combobox.Option
                        key={(opportunity as types.SearchResultOpportunity).id}
                        value={opportunity}
                      >
                        <OpportunitySearchResult
                          opportunity={
                            opportunity as types.SearchResultOpportunity
                          }
                          opportunityTypes={opportunityTypes}
                        />
                      </Combobox.Option>
                    ))
                  }
                </ResultGroup>
              </Tab.Panel>
              <Tab.Panel>
                <ResultGroup
                  title="Organizations"
                  data={organizationData}
                  externalLink={{
                    pathname: "/discover/organizations",
                    query: { q: query },
                  }}
                >
                  {(organizationData) =>
                    organizationData.map((org) => (
                      <Combobox.Option
                        key={(org as types.SearchResultOrganization).id}
                        value={org}
                      >
                        <OrganizationSearchResult
                          organization={org as types.SearchResultOrganization}
                        />
                      </Combobox.Option>
                    ))
                  }
                </ResultGroup>
                <ResultGroup
                  title="More Organizations"
                  data={ninjaOrganizationData}
                >
                  {(ninjaOrganizationData) =>
                    ninjaOrganizationData.map((org) => (
                      <Combobox.Option
                        key={(org as types.SearchResultNinjaOrganization).uuid}
                        value={org}
                      >
                        <NinjaOrganizationSearchResult
                          ninja_organization={
                            org as types.SearchResultNinjaOrganization
                          }
                        />
                      </Combobox.Option>
                    ))
                  }
                </ResultGroup>
              </Tab.Panel>
              <Tab.Panel>
                <ResultGroup
                  title="Notes"
                  data={meetingnoteData}
                  isMobile
                  externalLink={{
                    pathname: "meeting_notes",
                    query: { q: query },
                  }}
                >
                  {(meetingnoteData) =>
                    meetingnoteData.map((meeting_note) => (
                      <Combobox.Option
                        key={(meeting_note as types.SearchResultMeetingNote).id}
                        value={meeting_note}
                      >
                        <MeetingNoteSearchResult
                          meeting_note={
                            meeting_note as types.SearchResultMeetingNote
                          }
                        />
                      </Combobox.Option>
                    ))
                  }
                </ResultGroup>
              </Tab.Panel>
              <Tab.Panel>
                <ResultGroup
                  isMobile
                  title="People"
                  data={personData}
                  externalLink={{
                    pathname: "/discover/people",
                    query: { q: query },
                  }}
                >
                  {(personData) =>
                    personData.map((p) => (
                      <Combobox.Option
                        key={(p as types.SearchResultPerson).id}
                        value={p}
                      >
                        <PersonSearchResult
                          person={p as types.SearchResultPerson}
                        />
                      </Combobox.Option>
                    ))
                  }
                </ResultGroup>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </Combobox.Options>
    </div>
  );
};

const QuickSearchContext = createContext({
  hideResult(): void {},
});

interface QuickSearchProps {
  isMobile?: boolean;
}

const QuickSearch = ({ isMobile = false }: QuickSearchProps) => {
  const { types: opportunityTypes } = useOpportunity();
  const options = {
    revalidateOnFocus: true,
    revalidateOnMount: true,
    revalidateOnReconnect: true,
  };

  const comboboxButtonRef = useRef<any>(null);
  const comboboxInputRef = useRef<any>(null);

  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);

  const { data: personData } = useSWR<types.SearchResultPerson[]>(
    debouncedQuery !== ""
      ? `/api/people_map/search/people?q=${debouncedQuery}`
      : null,
    getter,
    options,
  );
  const { data: organizationData } = useSWR<types.SearchResultOrganization[]>(
    debouncedQuery !== ""
      ? `/api/people_map/search/organization?q=${debouncedQuery}`
      : null,
    getter,
    options,
  );
  const { data: ninjaOrganizationData } = useSWR<
    types.SearchResultNinjaOrganization[]
  >(
    debouncedQuery !== ""
      ? `/api/people_map/search/external_organization?q=${debouncedQuery}`
      : null,
    getter,
    options,
  );

  const { data: meetingnoteData } = useSWR<types.SearchResultMeetingNote[]>(
    debouncedQuery !== ""
      ? `/api/people_map/search/meeting_notes?q=${debouncedQuery}`
      : null,
    getter,
    options,
  );

  const { data: opportunityData } = useSWR<types.SearchResultOpportunity[]>(
    debouncedQuery !== ""
      ? `/api/people_map/search/opportunity?q=${debouncedQuery}`
      : null,
    getter,
    options,
  );

  const router = useRouter();

  const handleKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      comboboxButtonRef.current.click();
    }
  };

  const hideResult = () => {
    setQuery("");
    comboboxButtonRef.current.click();
  };

  useEffect(() => {
    comboboxInputRef.current.value = "";
  }, [router]);

  return (
    <div className="relative z-top w-full max-w-2xl">
      <Combobox value={query}>
        {({ open }) => (
          <>
            <div className="relative mr-2">
              <Combobox.Button
                className="w-full"
                as="div"
                ref={comboboxButtonRef}
              >
                <Combobox.Input
                  ref={comboboxInputRef}
                  onChange={(event) => {
                    setQuery(event.target.value);
                  }}
                  onKeyUp={handleKeyUp}
                  className={`border-1 peer block h-8 w-full rounded-full border-white bg-transparent pl-8 text-2xs text-white placeholder:text-white focus:!border-white md:border-gray-400 md:text-xs md:text-black md:placeholder:text-gray-400 md:focus:border-blue-menu lg:pl-10`}
                  placeholder="Search People, Companies, Notes or Opportunities"
                />
              </Combobox.Button>
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 peer-focus:text-blue-menu">
                <MagnifyingGlassIcon
                  className={`h-4 w-4 text-white md:text-gray-400 lg:h-5 lg:w-5`}
                  aria-hidden="true"
                />
              </div>
            </div>
            <QuickSearchContext.Provider value={{ hideResult }}>
              {query.length > 0 && (
                <SearchResults
                  personData={personData}
                  organizationData={organizationData}
                  ninjaOrganizationData={ninjaOrganizationData}
                  meetingnoteData={meetingnoteData}
                  opportunityData={opportunityData}
                  query={query}
                  opportunityTypes={opportunityTypes || []}
                  isMobile={isMobile}
                  open={open}
                />
              )}
              {query.length === 0 && (
                <SearchHistory
                  showHistory={open}
                  opportunityTypes={opportunityTypes || []}
                />
              )}
            </QuickSearchContext.Provider>
          </>
        )}
      </Combobox>
    </div>
  );
};

export default QuickSearch;
