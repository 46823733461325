import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { getTitle } from "./Utils/commons";
type BrowserTitleProps = {
  navigation?: string;
  title?: string;
};

export default function BrowserTitle({
  navigation = "",
  title,
}: BrowserTitleProps) {
  const router = useRouter();
  const currentUrl = router.asPath.slice(1).split("?")[0];
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia("(display-mode: minimal-ui)");

    // Function to handle changes in the display mode
    const handleDisplayModeChange = (event: any) => {
      setIsPWA(event.matches);
    };

    // Add event listener to listen for changes in the display mode
    mediaQueryList.addEventListener("change", handleDisplayModeChange);

    // Set the initial value for isPWA
    setIsPWA(mediaQueryList.matches);

    // Clean up the event listener on component unmount
    return () => {
      mediaQueryList.removeEventListener("change", handleDisplayModeChange);
    };
  }, []);
  const pageTitle = isPWA ? `${currentUrl}` : getTitle(navigation, title);
  return (
    <Head>
      <title>{pageTitle}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
      />
    </Head>
  );
}
