import * as api from "../../api";
import Select from "./Select";

type GroupSelectAll = {
  loadOptions?: (inputValue: string) => Promise<any>;
  onChange?: (value: any) => void;
};

export default function GroupSelectAll({ ...props }) {
  const loadOptions = api.autocompleteAllGroups;
  return <Select {...props} loadOptions={loadOptions} isClearable={true} />;
}
