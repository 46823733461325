import { useState } from "react";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import { getter } from "../../api";
import * as types from "../../api/types";
import { Option } from "../../api/types";
import Select from "./Select";

type AutocompleteMultiselectProps = {
  autocompleteEndpoint: string;
  selected: Option[];
  onChange: (selected: any) => void;
  creatable?: boolean;
  id?: string;
  isMulti?: boolean;
  isClearable?: boolean;
  placeholder?: string;
  className?: string;
  onBlur?: () => void;
};
const AutocompleteMultiselect = ({
  autocompleteEndpoint,
  selected,
  onChange,
  creatable = false,
  isMulti = true,
  isClearable = false,
  className = "",
  placeholder,
  onBlur,
  ...props
}: AutocompleteMultiselectProps) => {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const { data } = useSWR<types.AutocompleteResult[]>(
    `${autocompleteEndpoint}?q=${debouncedQuery}`,
    getter,
  );

  return (
    <Select
      setInputValue={setQuery}
      placeholder={placeholder}
      value={selected}
      onChange={onChange as (selected: any) => void}
      options={data}
      isMulti={isMulti}
      isClearable={isClearable}
      creatable={creatable}
      className={className}
      onBlur={onBlur}
      {...props}
    />
  );
};

export default AutocompleteMultiselect;
