import { ApolloPersonDetail } from "../api/types";
import Spinner from "./Spinner";

type ApolloSuggestionProps = {
  handleClickFill: (apollo: ApolloPersonDetail) => void;
  apolloData: ApolloPersonDetail;
};

/**
 * Renders the Apollo information in a modal popup.
 * This component requires 1) state containing apollo data to be passed in, 2) state to handle modal opening, 3) method to handle the fill button
 * @param props
 * @returns
 */
const ApolloSuggestion = ({
  handleClickFill,
  apolloData,
}: ApolloSuggestionProps) => {
  return (
    <div>
      <div className="flex flex-row">
        <div className="w-[10%]">
          <img
            src={
              apolloData?.image_url
                ? apolloData.image_url
                : "https://storage.googleapis.com/rhombus-public-images/user.png"
            }
            alt="Profile"
            className="grid items-center justify-center rounded-full text-white"
          />
        </div>
        <div className="flex w-3/4 flex-col gap-y-1 px-4 py-2">
          {apolloData?.first_name && (
            <h2 className="text-xs text-gray-600">
              <b>First Name:</b> {apolloData.first_name}
            </h2>
          )}
          {apolloData?.last_name && (
            <h2 className="text-xs text-gray-600">
              <b>Last Name:</b> {apolloData?.last_name}
            </h2>
          )}
          {apolloData?.email && (
            <p className="text-xs text-gray-600">
              <b>Email:</b> {apolloData.email}
            </p>
          )}
          {apolloData?.title && (
            <p className="text-xs text-gray-600">
              <b>Title:</b> {apolloData.title}
            </p>
          )}
          {apolloData?.linkedin && (
            <p className="text-xs text-gray-600">
              <b>Linkedin:</b> {apolloData.linkedin}
            </p>
          )}
          {apolloData?.country && (
            <p className="text-xs text-gray-600">
              <b>Location:</b> {apolloData.country.label}
            </p>
          )}
          {apolloData?.organization_name && (
            <p className="text-xs text-gray-600">
              <b>Organization:</b> {apolloData.organization_name}
            </p>
          )}
        </div>
      </div>
      {apolloData && (
        <div className="mt-2 flex justify-end">
          <button
            onClick={() => {
              handleClickFill(apolloData);
            }}
            className="btn-primary"
          >
            {false ? (
              <div className="flex flex-row items-center gap-x-2 text-white">
                <div>
                  <Spinner className="h-4 w-4 text-white" />
                </div>
                <div>Filling</div>
              </div>
            ) : (
              "Fill"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default ApolloSuggestion;
