import { DocumentIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { AutocompleteResult } from "../api/types";

type FileThumbnailProps = {
  file: File | string;
  field: string;
  value: File[] | AutocompleteResult[];
  setValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
};
export const FileThumbnail = ({
  file,
  field,
  value,
  setValue,
}: FileThumbnailProps) => {
  return (
    <div className="flex flex-row items-center justify-between rounded-2xl bg-slate-100 p-3 text-xs">
      <div className="flex flex-row items-center space-x-3">
        <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-menu">
          <DocumentIcon className="h-6 w-6 text-white" />
        </div>
        <p className="break-all">
          <div className="grid-cols grid">
            <div>{typeof file === "string" ? file : file.name}</div>
            <div>
              {typeof file === "string"
                ? ""
                : `${(file.size / 1048576).toFixed(2)} MB`}
            </div>
          </div>
        </p>
      </div>
      <XMarkIcon
        className="h-4 w-4 cursor-pointer"
        onClick={() => {
          if (typeof file === "string") {
            setValue(
              field,
              (value as AutocompleteResult[]).filter((f) => f.label !== file),
            );
          } else {
            setValue(
              field,
              (value as File[]).filter((f) => f !== file),
            );
          }
        }}
      />
    </div>
  );
};

export default FileThumbnail;
