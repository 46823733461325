export const SpinnerCustom = ({ className = "" }) => (
  <div className={`flex h-64 items-center justify-center ${className}`}>
    <img
      src="/loading.gif"
      className={`inline-block h-28 w-64 ${className}`}
      alt="Loading"
    />
  </div>
);
export default SpinnerCustom;
