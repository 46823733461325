import { Popover } from "@headlessui/react";
import { ArchiveBoxIcon } from "@heroicons/react/24/outline";
import { PropsWithChildren, useState } from "react";
import { usePopper } from "react-popper";

export const PopoverComponent = ({ children }: PropsWithChildren) => {
  let [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null,
  );
  let [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [
      { name: "arrow", options: { element: arrowElement } },
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Popover>
      {({ open }) => {
        return (
          <>
            {open && (
              <div className="absolute left-0 top-0 z-10 h-full w-full bg-black opacity-30"></div>
            )}
            <Popover.Button
              ref={setReferenceElement}
              className="block outline-none"
              id="show-history-chat-button"
            >
              <ArchiveBoxIcon className="h-5 w-5 stroke-black" />
            </Popover.Button>
            <Popover.Panel
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
              className={`z-top ml-1 h-[86vh] w-[98%] rounded-md bg-white p-2`}
            >
              <>
                {children}
                <div
                  ref={setArrowElement}
                  style={{ ...styles.arrow, left: "-4px" }}
                  className="popper-arrow"
                />
              </>
            </Popover.Panel>
          </>
        );
      }}
    </Popover>
  );
};
