// Key is the string displayed, value is the colour hex code
type ColoredTagProps = {
  mappings: { [key: string]: string[] };
  value: string;
  className?: string;
  isTag?: boolean;
  upperCase?: boolean;
};

export const generateColoredTagMappings = (
  values: string[],
  colors: string[][],
) => {
  // If there are insufficient colors for the values
  const colorsLength = colors.length;
  if (values.length > colors.length) {
    for (var i = 0; i < Math.ceil(colorsLength / values.length); i++) {
      colors = colors.concat(colors);
    }
  }
  let colorMappings: { [key: string]: string[] } = {};
  values.forEach((value: string, index: number) => {
    colorMappings[value] = colors[index];
  });
  return colorMappings;
};

export const ColoredTag = ({
  mappings,
  value,
  className,
  isTag,
  upperCase = true,
}: ColoredTagProps) => {
  var backgroundColor: string;
  var textColor: string;
  mappings[value] !== undefined
    ? (backgroundColor = mappings[value][0])
    : (backgroundColor = "gainsboro");
  mappings[value] !== undefined
    ? (textColor = mappings[value][1])
    : (textColor = "black");

  if (!isTag) {
    return (
      <div className="text-2xs font-bold" style={{ color: backgroundColor }}>
        {upperCase ? value.toUpperCase() : value}
      </div>
    );
  }

  const classes = `inline-block py-1 px-2 rounded-full text-center text-${textColor} overflow-hidden whitespace-nowrap ${className}`;
  return (
    <div className={classes} style={{ backgroundColor: backgroundColor }}>
      {value}
    </div>
  );
};
