export const InvestmentSectorDict: Record<string, any> = {
  Consumer: [
    "E-Commerce",
    "D2C/Brands",
    "Travel Tech",
    "F&B",
    "Pet care",
    "Social",
    "Media/Content",
    "Creator Economy",
    "Others",
  ],
  Enterprise: [
    "Cybersecurity",
    "Infrastructure",
    "SMB SaaS",
    "IoT and Hardware",
    "Enterprise SaaS",
    "Others",
  ],
  FinTech: [
    "Lending",
    "Wealth",
    "Payments",
    "Banking Infrastructure",
    "Neo Banking",
    "Insurance",
    "Web3",
    "Others",
  ],
  Agritech: [
    "Market Linkage",
    "Financing",
    "Farm Inputs",
    "Farming as a Service",
    "Precision Agriculture & Automation",
    "Others",
  ],
  "B2B Marketplaces": [],
  HealthTech: [],
  Sustainability: [],
  Mobility: [],
  DeepTech: [],
  EdTech: [],
  Gaming: [],
  PropertyTech: [],
  Logistics: [],
  Others: ["Others"],
};
