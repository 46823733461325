import { useState } from "react";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import { getter } from "../../api";
import * as types from "../../api/types";
import { TechnologiesDetail } from "../../api/types";
import Select from "./Select";

function formatIndustriesOption(result: TechnologiesDetail) {
  return (
    <div className="flex items-center gap-x-3">
      <div>
        <div>{result.label}</div>
      </div>
    </div>
  );
}

type IndustriesSelectProps = {
  isMulti: boolean;
  id: string;
  value: types.Option;
  onChange: (selected: any) => void;
};
export default function IndustriesSelect({ ...props }: IndustriesSelectProps) {
  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const { data } = useSWR<types.AutocompleteResult[]>(
    `/api/people_map/autocomplete/industries?q=${debouncedQuery}`,
    getter,
  );

  return (
    <Select
      options={data}
      formatOptionLabel={formatIndustriesOption}
      setInputValue={setQuery}
      isClearable
      {...props}
    />
  );
}
