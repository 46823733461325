import {
  ArchiveBoxIcon,
  ClipboardIcon,
  FireIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import Link from "next/link";
import { useRouter } from "next/router";
import DropDownMenu from "./CreateDropDownMenu";
import { Llama } from "./Llama";
import ProfileMenu from "./ProfileMenu";
import QuickSearch from "./QuickSearch";

const Profile = () => {
  return <ProfileMenu navOpen={true} />;
};

const Tooltip = ({ value }: { value: string }) => {
  return (
    <div
      id="tooltip-bottom"
      role="tooltip"
      className="tooltip invisible absolute left-0 top-9 z-top inline-block w-max rounded-lg bg-gray-900 py-1 px-2 text-3xs font-medium text-white shadow-sm group-hover:visible"
    >
      {value}
      <div className="tooltip-arrow" data-popper-arrow></div>
    </div>
  );
};

const Header = () => {
  const router = useRouter();
  const showAskVal = !router.asPath.includes("/askval");
  return (
    <div>
      <div className="mb-2 mr-6 ml-6 flex hidden flex-row justify-end space-x-1 md:mr-10 md:ml-10 md:flex md:space-x-4">
        <QuickSearch />
        <DropDownMenu />
        <div className="hidden space-x-2 md:flex">
          <div
            id="ask-val-desktop-button"
            className={`group relative cursor-pointer ${
              !showAskVal && "opacity-50"
            }`}
            onClick={() => {
              if (showAskVal) {
                window.sessionStorage.setItem("showValStatus", "true");
                window.dispatchEvent(new Event("storage"));
              }
            }}
          >
            <Llama customClass={"w-5 h-5 mr-2"} />
            <Tooltip value="Ask Val" />
          </div>
          <Link href={`/collab`}>
            <div className="group relative">
              <ArchiveBoxIcon className="relative mt-1 h-6 w-6 stroke-1 text-gray-500" />
              <Tooltip value="Collaboration" />
            </div>
          </Link>
          <Link href={`/tasks`}>
            <div className="group relative">
              <ClipboardIcon className="relative mt-1 h-6 w-6 stroke-1 text-gray-500" />
              <Tooltip value="Tasks" />
            </div>
          </Link>
          <Link href={`/activity_logs`}>
            <div className="group relative">
              <div className="mt-1 h-6 w-6">
                <img
                  src="/activity.svg"
                  alt="Activity Icon"
                  className="h-6 w-6 text-gray-500"
                />
              </div>
              <Tooltip value="Activity log" />
            </div>
          </Link>
          <Link href={`/help`}>
            <div className="group relative">
              <QuestionMarkCircleIcon className="mt-1 h-6 w-6 stroke-1 text-gray-500" />
              <Tooltip value="Help" />
            </div>
          </Link>
          <Link href={`/changelog`}>
            <div className="group relative">
              <FireIcon className="mt-1 h-6 w-6 stroke-1 text-gray-500" />
              <Tooltip value="Change Log" />
            </div>
          </Link>
          <Profile />
        </div>
      </div>
    </div>
  );
};

export default Header;
