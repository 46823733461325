import { PlusIcon } from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import useSWR from "swr";
import { useDebounce } from "use-debounce";
import * as api from "../../api";
import {
  OrganizationAutocompleteResult,
  OrganizationDetail,
} from "../../api/types";
import Avatar from "../Avatar";
import NewModal from "../NewModal";
import Select from "./Select";
import OrganizationSelectSkeleton from "../Skeletons/OrganizationSelectSkeleton";
import OrganizationForm from "../discover/Organizations/OrganizationForm";

const updateValue = ({
  isMulti,
  organization,
  currentValues,
  setNewValue,
}: {
  isMulti: boolean | undefined;
  organization: OrganizationDetail;
  currentValues: OrganizationAutocompleteResult[] | undefined;
  setNewValue: ((value: any) => void) | undefined;
}) => {
  const newOrganization: OrganizationAutocompleteResult = {
    label: organization.name,
    value: organization.id,
    domain: organization.domain,
    image_url: organization.logo_url,
  };

  if (setNewValue) {
    if (isMulti) {
      setNewValue([...(currentValues || []), newOrganization]);
    } else {
      setNewValue([...[], newOrganization]);
    }
  }
};

function formatOrganizationOption(
  result: OrganizationAutocompleteResult,
  isLoading?: boolean,
) {
  if (result?.isNinjaOrg && isLoading) {
    return <OrganizationSelectSkeleton />;
  }

  if (!result) return <div></div>;

  const showAvatar = result?.label?.indexOf("Create new organization") === -1;
  return (
    <div className="flex w-full flex-row items-center gap-x-3">
      {showAvatar && (
        <div className="hidden md:block">
          <Avatar
            name={result.label}
            size={28}
            url={result.image_url}
            className="rounded-none"
          />
        </div>
      )}
      <div className="flex w-full flex-1 flex-col items-start text-2xs">
        <div className="flex w-full flex-row justify-start">
          <div className="flex flex-row">
            <span>
              <b className="text-left font-bold">{result.label}</b>{" "}
              {result.domain ? (
                <b className="text-left font-bold">{` . ${result.domain}`}</b>
              ) : (
                ""
              )}{" "}
              {result.country?.label ? (
                <>
                  {` . `}
                  <ReactCountryFlag
                    countryCode={result.country.value.toLocaleUpperCase()}
                    svg
                    style={{
                      width: "2em",
                      height: "2em",
                    }}
                    title="US"
                  />
                </>
              ) : (
                <></>
              )}
            </span>
          </div>
        </div>
        <div className="text-2xs line-clamp-2">{result?.description}</div>
      </div>
    </div>
  );
}

function formatOrganizationOptionSelectedValue({
  result,
  showFullInfo = false,
}: {
  result: OrganizationAutocompleteResult;
  showFullInfo?: boolean;
}) {
  const showAvatar = result.label?.indexOf("Create new organization") === -1;
  return (
    <div className="flex w-full flex-row items-center gap-x-3">
      {showAvatar && (
        <div className="hidden md:block">
          <Avatar
            name={result.label}
            size={28}
            url={result.image_url}
            className="rounded-none"
          />
        </div>
      )}
      <div className="flex w-full flex-1 flex-col items-start text-2xs">
        {showFullInfo ? (
          <>
            <div className="flex w-full flex-row justify-start">
              <div className="flex flex-row">
                <span>
                  <b className="text-left font-bold">{result.label}</b>{" "}
                  {result.domain ? (
                    <b className="text-left font-bold">{` . ${result.domain}`}</b>
                  ) : (
                    ""
                  )}{" "}
                  {result.country?.label ? (
                    <>
                      {` . `}
                      <ReactCountryFlag
                        countryCode={result.country.value.toLocaleUpperCase()}
                        svg
                        style={{
                          width: "2em",
                          height: "2em",
                        }}
                        title="US"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </div>
            <div className="!grid w-full text-2xs line-clamp-1">
              <p className="truncate"> {result.description}</p>
            </div>
          </>
        ) : (
          <b className="text-left font-bold">{result.label}</b>
        )}
      </div>
    </div>
  );
}

type OrganizationSelectProps = {
  id?: string;
  withNinja?: boolean;
  loadOrganizations?: (
    query: string,
  ) => Promise<OrganizationAutocompleteResult[]>;
  loadNinjaOrganizations?: (
    query: string,
  ) => Promise<OrganizationAutocompleteResult[]>;
  flexible: boolean;
  ignoredList?: OrganizationAutocompleteResult[];
  directCreate?: boolean;
  value?: OrganizationAutocompleteResult[];
  setValue?: (value: any) => void;
  widthFull?: boolean;
  fixWidth?: boolean;
  hasNoOption?: boolean;
  showFullInfo?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  onChange: any;
  placeholder?: string;
  className?: string;
  onBlur?: () => void;
};

export default function OrganizationSelect<IsMulti extends boolean>({
  id = "",
  loadOrganizations,
  loadNinjaOrganizations,
  flexible,
  isMulti,
  ignoredList,
  directCreate = false,
  value,
  setValue,
  isClearable = false,
  fixWidth = false,
  withNinja = false,
  widthFull = false,
  hasNoOption = false,
  showFullInfo = false,
  onChange,
  placeholder,
  className,
  onBlur,
  ...props
}: OrganizationSelectProps) {
  const organizationLoader = loadOrganizations
    ? loadOrganizations
    : api.autocompleteOrganizations;
  const ninjaOrganizationLoader = withNinja
    ? loadNinjaOrganizations
      ? loadNinjaOrganizations
      : api.autocompleteNinjaOrganizations
    : null;

  const [query, setQuery] = useState("");
  const [debouncedQuery] = useDebounce(query, 500);
  const [isOpen, setIsOpen] = useState(false);
  const [initialOptions, setInitialOptions] = useState<any>();
  const { data: organizations } = useSWR(
    [organizationLoader, debouncedQuery],
    () => organizationLoader(debouncedQuery),
  );
  const organizations_domains = organizations?.map((org) => org.domain);
  let { data: ninjaOrganizations, isValidating } = useSWR(
    ninjaOrganizationLoader ? [ninjaOrganizationLoader, debouncedQuery] : null,
    ninjaOrganizationLoader
      ? () => ninjaOrganizationLoader(debouncedQuery)
      : null,
  );
  ninjaOrganizations = ninjaOrganizations?.filter(
    (org) => !organizations_domains?.includes(org.domain),
  );

  const handleInputChange = (newValue: string) => setQuery(newValue);

  let organizationsFilter: OrganizationAutocompleteResult[] | undefined = [];
  let ninjaOrganizationsFilter: OrganizationAutocompleteResult[] | undefined =
    [];
  if (ignoredList && ignoredList.length > 0) {
    if (organizations && organizations.length > 0) {
      organizations.forEach((org) => {
        const isNotExisted =
          ignoredList.filter((item) => item.value === org.value).length === 0;
        if (isNotExisted) {
          organizationsFilter?.push(org);
        }
      });
    }
    if (ninjaOrganizations && ninjaOrganizations.length > 0) {
      ninjaOrganizations.forEach((org) => {
        const isNotExisted =
          ignoredList.filter((item) => item.value === org.value).length === 0;
        if (isNotExisted) {
          ninjaOrganizationsFilter?.push({ ...org, isNinjaOrg: true });
        }
      });
    }
  } else {
    organizationsFilter = organizations;
    ninjaOrganizationsFilter = (ninjaOrganizations || []).map((org) => {
      return {
        ...org,
        isNinjaOrg: true,
      };
    });
  }

  const options = withNinja
    ? [
        {
          label: "Organizations",
          options: hasNoOption
            ? [
                { label: "No Organization", value: 0 },
                ...(organizationsFilter || []),
              ]
            : organizationsFilter || [],
        },
        {
          label: "More Organizations",
          options: ninjaOrganizationsFilter || [],
        },
      ]
    : [
        {
          options: organizationsFilter || [],
        },
      ];

  useMemo(() => {
    setInitialOptions(options);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="flex items-center">
        <Select
          id={id}
          isGroup={true}
          options={
            directCreate &&
            options[0].options.length === 0 &&
            options[1]?.options.length === 0
              ? initialOptions
              : options
          }
          onInputChange={handleInputChange}
          placeholder={placeholder || "Create or Search Organizations"}
          creatable={directCreate}
          formatOptionLabel={formatOrganizationOption}
          formatOptionLabelSelected={formatOrganizationOptionSelectedValue}
          {...props}
          className={`w-full text-xs ${!flexible ? "mr-2 max-w-md" : ""} ${
            fixWidth ? "max-w-full md:w-11/12" : ""
          } ${widthFull ? "max-w-full" : ""} ${className}`}
          isClearable={directCreate || isClearable}
          value={value}
          onChange={onChange}
          isMulti={isMulti}
          onBlur={onBlur}
        />
        {!flexible && (
          <>
            <div
              className="flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-blue-menu sm:h-5 sm:w-5"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <PlusIcon className="h-4 w-4 fill-white sm:h-5 sm:w-5" />
            </div>
          </>
        )}
      </div>
      <NewModal
        open={isOpen && !directCreate}
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <OrganizationForm
          isOpen={isOpen}
          title="Add New Organization"
          setIsOpen={setIsOpen}
          newOrganization={(organization) => {
            updateValue({
              isMulti: isMulti,
              organization,
              currentValues: value,
              setNewValue: setValue,
            });
          }}
          isModal={true}
          icon="PlusCircleIcon"
        />
      </NewModal>
    </>
  );
}
