import { classNames } from "../utils";

type FormTextAreaFieldProps = {
  name: string;
  label: string;
  required: boolean;
  value: string;
  onChange: (event: any) => void;
  errors: string | undefined;
  touched: boolean;
  isClearable?: boolean;
  rows?: number;
  placeHolder?: string;
  tooltip?: JSX.Element;
  customClass?: string;
};
const FormTextAreaField = ({
  name,
  required,
  label,
  errors,
  touched,
  isClearable,
  placeHolder,
  tooltip,
  customClass = "",
  ...props
}: FormTextAreaFieldProps) => {
  return (
    <div className={`grid ${customClass}`}>
      <div className="flex shrink-0">
        <label
          className="text-xs font-semibold leading-6 text-gray-900"
          htmlFor={name}
        >
          {label}
        </label>
        {required ? (
          <div className="text-sm font-semibold text-red-600">*</div>
        ) : (
          ""
        )}
        {tooltip}
      </div>
      <div className="relative w-full">
        <textarea
          placeholder={placeHolder}
          className={classNames(
            "border-1 block w-full rounded-md text-xs",
            touched && errors
              ? "border-red-500"
              : touched && !errors
                ? "border-blue-menu"
                : "border-gray-300",
          )}
          id={name}
          name={name}
          {...props}
        ></textarea>
        <div
          className={classNames(
            "flex flex-row justify-start",
            touched && errors ? "text-2xs text-red-600" : "hidden",
          )}
        >
          {errors}
        </div>
      </div>
    </div>
  );
};

export default FormTextAreaField;
