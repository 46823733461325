import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import DOMPurify from "dompurify";
import { Fragment } from "react";
import Spinner from "./Spinner";

type ConfirmationModalProps = {
  open: boolean;
  title: string;
  processing: boolean;
  processingText: string;
  subtitle?: string;
  submitButtonText: string;
  closeText?: string;
  showCrossButton?: boolean;
  isEditingForm?: boolean;
  onClose: () => void;
  onDiscardChanges?: () => void;
  onSubmit: () => void;
  isBlueButton?: boolean;
};
export const ConfirmationModal = ({
  open,
  title,
  processing,
  processingText = "",
  subtitle,
  submitButtonText,
  closeText = "Close",
  showCrossButton = false,
  isEditingForm = false,
  onClose,
  onDiscardChanges = () => {},
  onSubmit,
  isBlueButton = false,
}: ConfirmationModalProps) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={isEditingForm ? onDiscardChanges : onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="block min-h-screen px-4 text-center">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <Dialog.Panel className="relative inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left align-middle shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  {showCrossButton && (
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={isEditingForm ? onDiscardChanges : onClose}
                    >
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  )}
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title className="text-sm font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {subtitle && (
                        <p
                          className="whitespace-pre-line text-xs text-gray-500"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(subtitle, {
                              FORBID_TAGS: ["style"],
                            }),
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    className={`inline-flex w-full justify-center rounded-full ${
                      isEditingForm || isBlueButton
                        ? "bg-blue-menu hover:bg-blue-menu"
                        : "bg-red-500 hover:bg-red-500"
                    } px-3 py-2 text-2xs font-semibold text-white shadow-sm sm:ml-3 sm:w-auto md:text-xs`}
                    onClick={onSubmit}
                  >
                    {processing ? (
                      <div className="flex flex-row items-center gap-x-2 text-white">
                        <div>
                          <Spinner className="h-4 w-4 text-white" />
                        </div>
                        <div>{processingText}</div>
                      </div>
                    ) : (
                      submitButtonText
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-full bg-white px-3 py-2 text-2xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto md:text-xs"
                    onClick={onClose}
                  >
                    {closeText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
