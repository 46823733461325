import { useField } from "formik";
import UserSelect from "../Select/UserSelect";
import { classNames } from "../utils";

type FormUserSelectFieldProps = {
  id?: string;
  name: string;
  label: string;
  required: boolean;
  errors: string | undefined;
  touched: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  customClass?: string;
  placeholder?: string;
};
const FormUserSelectField = ({
  id,
  name,
  required,
  label,
  errors,
  touched,
  isMulti = false,
  isClearable = false,
  customClass,
  placeholder,
}: FormUserSelectFieldProps) => {
  const [input, meta, helpers] = useField(name);

  const { value, checked, onBlur } = input;
  const { setValue } = helpers;

  const isInvalid =
    touched && touched !== undefined && errors && errors !== undefined;

  return (
    <div>
      <div className="flex items-end">
        <label className="text-xs font-semibold" htmlFor={name}>
          {label}
        </label>
        <div>
          {required ? (
            <div className="text-sm font-semibold text-red-600">*</div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className={`${customClass} ${isInvalid && "border-red-500"}`}>
        <UserSelect
          id={id}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          isMulti={isMulti}
          isClearable={isClearable}
          placeholder={placeholder}
        />
      </div>
      <div
        className={classNames(
          touched && errors ? "text-2xs text-red-600" : "hidden",
        )}
      >
        {errors}
      </div>
    </div>
  );
};

export default FormUserSelectField;
