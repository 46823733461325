import { ColDef } from "ag-grid-community";
import { AxiosError, AxiosResponse } from "axios";
import { parseISO, subMonths } from "date-fns";
import {
  calculateVisibility,
  getOpportunity,
  getOpportunityTypes,
  updateOpportunity,
} from "../api";
import {
  AttachmentDetail,
  Group,
  OpportunityDetail,
  OpportunityTableDetail,
  OpportunityType,
  Tag,
  UserAutocompleteResult,
  UserStub,
} from "../api/types";
import { DEFAULT_ERROR_MESSAGE } from "./Utils/constant";

export function fullName(first: string, last: string) {
  if (last === undefined || last.length === 0) {
    return first;
  } else {
    return `${first} ${last}`;
  }
}

export function isValidPk(pk: string | string[] | undefined) {
  const id = parseInt(pk as string);
  return !isNaN(id);
}

export function isValidCompanyId(pk: string | string[] | undefined) {
  const id = parseInt(pk as string);
  const is32CharsLong = String(pk).replace(/-/g, "").length === 32;
  return String(pk).startsWith("atom") || !isNaN(id) || is32CharsLong;
}

export function classNames(...classes: (string | undefined | false)[]) {
  return classes.filter(Boolean).join(" ");
}

export const average = (arr: number[]) =>
  arr.reduce((p: number, c: number) => p + c, 0) / arr.length;

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function decapitalize(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function getCurrentDate(): Date {
  throw new Error("Function not implemented.");
}

export function sortByString<T>(originalArray: T[], field: keyof T) {
  return originalArray.sort((a: T, b: T) =>
    (a[field] as any).localeCompare(b[field] as any),
  );
}

export function getDaysSinceLatestActivity(dateTimeStrings: string[]): number {
  const dateTimes = dateTimeStrings
    .map((d) => new Date(Date.parse(d)).getTime())
    .filter(Number);
  const maxDateTime = Math.max(...dateTimes);
  const currentDateTime = new Date().getTime();
  return Math.floor((currentDateTime - maxDateTime) / (24 * 60 * 60 * 1000));
}

export function lastActivity(
  lastActivity: any,
  latestCommentCreatedAt: any,
  createdAt: any,
) {
  const latest_activity = new Date(
    Math.max(
      Date.parse(lastActivity),
      latestCommentCreatedAt ? Date.parse(latestCommentCreatedAt) : -Infinity,
      Date.parse(createdAt),
    ),
  );
  return latest_activity;
}
export const updateOpportunityTable = async (data: OpportunityTableDetail) => {
  const opportunity = await getOpportunity(data.id).then(
    (response: AxiosResponse<OpportunityDetail>) => response.data,
  );

  //Sector vvseai
  if (!data.additional_fields?.sector) {
    delete data.additional_fields?.sub_sector;
  }
  //End Sector vvseai

  const visibility = calculateVisibility(opportunity.access_policy);
  let payload = {
    name: data.name,
    description: data.description,
    opportunity_type: data.opportunity_type.id as number,
    owner: data.owner.id,
    owner_groups: opportunity.owner_groups.map((option: Group) => option.id),
    funnel_stage: data.funnel_stage.id,
    visibility: visibility,
    groups: opportunity.access_policy
      ? opportunity.access_policy.groups.map((option: Group) => option.id)
      : [],
    users: opportunity.access_policy
      ? opportunity.access_policy.users.map((option: UserStub) => option.id)
      : [],
    rejected_at: data.rejected_at,
    rejected_reason: data.rejected_reason,
    to_revisit_at: data.to_revisit_at,
    to_revisit_reason: data.to_revisit_reason,
    additional_fields: data.additional_fields,
    tags: data.tags.map((tag: Tag) => tag.name),
    partner: data.partner?.id,
    team_members: data.team_members.map((option: UserStub) => option.id),
    attachment_ids: opportunity.attachments.map(
      (option: AttachmentDetail) => option.value,
    ),
    originator: data.originator?.id,
    source_organization: data.source_organization?.id,
    target_organization: data.target_organization?.id,
    contacts: data.contacts.map((option: UserStub) => option.id),
  };

  let currentAddtionalFields: any[] = [];
  const additional_fields = opportunity.opportunity_type.additional_fields;

  if (additional_fields) {
    currentAddtionalFields = Object.keys(additional_fields.properties) || [];
  }

  if (payload?.additional_fields) {
    const addtionalFieldsValue = Object.keys(payload?.additional_fields);

    if (addtionalFieldsValue?.length > 0) {
      addtionalFieldsValue.forEach((fieldName: string) => {
        if (!currentAddtionalFields.includes(fieldName)) {
          delete payload?.additional_fields?.[fieldName];
        }
      });
    }
  }

  const formData = new FormData();
  formData.append("params", JSON.stringify(payload));
  const submitFunction = updateOpportunity(formData as any, String(data.id));

  return submitFunction;
};

export const ConvertUsersAutoCompleteToUserStub = (
  usersAuto: UserAutocompleteResult[],
) => {
  return usersAuto.map((user) => ({
    id: user.value,
    name: user.label,
    email: user.email,
    image_url: user.image_url,
    person: user.value,
  }));
};

export const ConvertUsersStubToUserAutoComplete = (usersStub: any) => {
  return usersStub.map((user: UserStub) => ({
    id: user.id,
    label: user.name,
    email: user.email,
    image_url: user.image_url,
    value: user.id,
  }));
};

export interface TimeSeriesData {
  date: string;
  value: number;
}

export interface ParsedTimeSeriesData {
  date: Date;
  value: number;
}

/**
 * Function calculates the YoY growth. As the provided data might be incomplete, this function will get the latest data in the given year, and find the YoY change against the latest data in the year-1 year
 *
 * @param data Array of time series data point for calculating data
 * @param year year to be based on (e.g. if 2022, will calculate the 2022 on 2021 change)
 */
export const calculateYOYGrowth = (data: TimeSeriesData[], year: number) => {
  const parsedData = data.map((d) => ({
    date: new Date(d.date),
    value: d.value,
  }));

  // Reverse sort
  var currValue: number | undefined;
  var prevValue: number | undefined;

  const currDates = parsedData.filter((d) => d.date.getFullYear() === year);
  if (currDates.length > 0) {
    currValue = currDates.sort((a, b) =>
      a.date > b.date ? -1 : b.date > a.date ? 1 : 0,
    )[0].value;
  }

  const prev_dates = parsedData.filter(
    (d) => d.date.getFullYear() === year - 1,
  );
  if (prev_dates.length > 0) {
    prevValue = prev_dates.sort((a, b) =>
      a.date > b.date ? -1 : b.date > a.date ? 1 : 0,
    )[0].value;
  }

  if (currValue !== undefined && prevValue !== undefined) {
    return prevValue === 0
      ? 0
      : (((currValue - prevValue) / prevValue) * 100).toFixed(1);
  } else {
    return undefined;
  }
};

export type CalculateYOYGrowthV2Output = {
  value?: number;
  errorMessage?: string;
};

/**
 * Function is a V2 of the calculateYOYGrowth. This takes the current latest data for the year and find the nearest closest data 12 months ago, using the range as a search radius
 *
 * @param data
 * @param searchRadius
 * @param latestDataWithinXMonths latest data point must be within this number of months from today
 * @param monthsAgo difference between datapoints to compare. For YoY - 12, for 6-month delta - 6. This should be more than the searchRadius
 */
export const calculateYOYGrowthV2 = (
  data: TimeSeriesData[],
  searchRadius: number,
  monthsAgo: number,
  latestDataWithinXMonths: number = 6,
): CalculateYOYGrowthV2Output => {
  const parsedData: ParsedTimeSeriesData[] = data.map((d) => ({
    date: new Date(d.date),
    value: d.value,
  }));

  // Get latest date for this year
  const lowerCurrDateLimit = new Date();
  lowerCurrDateLimit.setMonth(
    lowerCurrDateLimit.getMonth() - latestDataWithinXMonths,
  );
  const currDates = parsedData.filter((d) => d.date >= lowerCurrDateLimit);
  const currDataPoint: ParsedTimeSeriesData | undefined =
    currDates.length <= 0
      ? undefined
      : currDates.sort((a, b) =>
          a.date > b.date ? -1 : b.date > a.date ? 1 : 0,
        )[0];
  // Early break if no datapoints found
  if (currDataPoint === undefined) {
    const errorMessage = `No data within ${latestDataWithinXMonths} months`;
    return {
      value: undefined,
      errorMessage: errorMessage,
    };
  }

  // Get 12 months ago's value +- searchRadius
  const currDateMinus12 = subMonths(currDataPoint.date, monthsAgo);

  const upperDateLimit = subMonths(
    currDataPoint.date,
    monthsAgo - searchRadius,
  );

  const lowerDateLimit = subMonths(
    currDataPoint.date,
    monthsAgo + searchRadius,
  );

  const searchDates = parsedData.filter(
    (d) => d.date >= lowerDateLimit && d.date <= upperDateLimit,
  );

  const prevDataPoint: ParsedTimeSeriesData | undefined =
    searchDates.length < 1
      ? undefined
      : searchDates.sort((a, b) => {
          var distanceA = Math.abs(
            currDateMinus12.getTime() - a.date.getTime(),
          );
          var distanceB = Math.abs(
            currDateMinus12.getTime() - b.date.getTime(),
          );
          return distanceA - distanceB;
        })[0];

  // Early break if no datapoints found
  if (prevDataPoint === undefined) {
    const errorMessage = `Insufficient data or no data within search radius of ${monthsAgo} month(s) with a search radius of +-${searchRadius} months`;
    return {
      value: undefined,
      errorMessage: errorMessage,
    };
  }

  if (currDataPoint.value !== undefined && prevDataPoint.value !== undefined) {
    const value: number =
      prevDataPoint.value === 0
        ? 0
        : ((currDataPoint.value - prevDataPoint.value) / prevDataPoint.value) *
          100;
    return {
      value: value,
      errorMessage: undefined,
    };
  } else {
    const errorMessage = `No data within either ${latestDataWithinXMonths} or ${monthsAgo} months ago.`;
    return { value: undefined, errorMessage: errorMessage };
  }
};

// Formulas
export const getReverseCumulativeCounts = (funnelMetrics: any): number[] => {
  const cumulativeSumReverse: number[] = [];
  let sum = 0;
  cumulativeSumReverse.unshift(
    ...funnelMetrics.reduceRight((acc: number[], curr: any) => {
      sum += curr.count;
      acc.push(sum);
      return acc;
    }, []),
  );
  return cumulativeSumReverse.reverse();
};

export const calculateConversionRates = function (
  cumulativeCounts: number[],
  denominatorIndex: number,
): number[] {
  const conversionRates = cumulativeCounts
    .slice(denominatorIndex + 1)
    .map((value, index) => {
      return cumulativeCounts[denominatorIndex] !== 0
        ? value / cumulativeCounts[denominatorIndex]
        : 0;
    });
  return conversionRates;
};

export const dateFormatISO = (date: string) => {
  return parseISO(date).toLocaleDateString("en-SG", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
};

export const formatDisplayComment = (text: string) => {
  return text.replace(/@\[([\w\s]*)\]\([\d]*\)/g, "@$1");
};

/**
 * Reorders ag grid columns
 * @param objList
 * @param headerName
 * @param newPosition
 * @returns
 */
export const findAndReorderAgGridColumns = (
  objList: Array<ColDef<any>>,
  headerName: string,
  newPosition: number,
) => {
  const index = objList.findIndex((obj: any) => obj.headerName === headerName);
  if (index !== -1) {
    const col = objList.splice(index, 1)[0];
    objList.splice(newPosition, 0, col);
  }
  return objList;
};

/**
 * Compares two column defs and checks if they are equal
 * @param col1
 * @param col2
 * @param columnsToCompare
 */
export function compareAgGridColumn<T>(
  col1: T[],
  col2: T[],
  columnsToCompare: (keyof T)[],
) {
  if (col1.length !== col2.length) {
    return false;
  }

  for (let i = 0; i < col1.length; i++) {
    const item1 = col1[i];
    const item2 = col2[i];

    for (const col of columnsToCompare) {
      if (item1[col] !== item2[col]) {
        return false;
      }
    }
  }

  return true;
}

export async function downloadImageAndConvertToFile(
  url: string,
): Promise<File> {
  try {
    let newUrl = url;
    const params = url.substring(url.indexOf("?") + 1, url.length);
    if (url.indexOf("?") > -1 && params.split("&").length > 0) {
      newUrl = url + "&force=true";
    } else {
      newUrl = url + "?force=true";
    }
    const response = await fetch(newUrl);

    if (!response.ok) {
      throw new Error("Failed to download the image");
    }

    // Convert the response body to a blob
    const blob = await response.blob();

    // Create a File object from the blob
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const file = new File([blob], filename, { type: blob.type });

    return file;
  } catch (error) {
    console.error("Error downloading and converting the image:", error);
    throw error;
  }
}

export const isValidUrl = (url: string) => {
  let httpPattern =
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
      url,
    );
  let pattern =
    /^[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(
      url,
    );
  return httpPattern || pattern;
};

/**
 * Takes a round name like 2023/2 Series A and returns Series A. If round name does not start with a number, returns the original input.
 * @param roundName
 * @returns
 */
export const extractRoundName = (roundName: string) => {
  const splitRoundName = roundName.split(" ");

  if (splitRoundName.length > 1 && !isNaN(parseInt(splitRoundName[0]))) {
    return splitRoundName.slice(1).join(" ");
  }
  return roundName;
};

export const getOpportunityTypesWrapper = async () => {
  const opportunityTypes = await getOpportunityTypes();
  const opportunityType = opportunityTypes.find(
    (item: OpportunityType) => item.name === "Investment Opportunity",
  );
  if (opportunityType) {
    const fieldDefinition =
      opportunityType.additional_fields?.$defs?.InvestmentSector;
    const values: string[] = fieldDefinition?.enum || [];
    return values.sort().map((val: string) => ({
      label: capitalize(val),
      value: capitalize(val),
    }));
  }
  return [];
};

export const getWebDomain = (rawWebsite: string) => {
  if (rawWebsite.startsWith("http://") || rawWebsite.startsWith("https://")) {
    return rawWebsite;
  } else if (rawWebsite.startsWith("www.")) {
    return `https://${rawWebsite}`;
  } else {
    return `https://www.${rawWebsite}`;
  }
};

export const calculateMonthsSince = (
  startYear: number,
  startMonth: number,
): number => {
  const currDate = new Date();
  const currYear = currDate.getFullYear();
  const currMonth = currDate.getMonth() + 1;
  return (currYear - startYear) * 12 + (currMonth - startMonth);
};

export const parseAxiosError = (error: AxiosError): string => {
  if (error.response) {
    return error?.response?.data?.error?.message || DEFAULT_ERROR_MESSAGE;
  } else {
    return error?.message || DEFAULT_ERROR_MESSAGE;
  }
};
