import useSWR from "swr";
import * as api from "../../api";
import { AutocompleteResult, Option } from "../../api/types";
import Select from "./Select";

type InvestorOrganizationSelectAllType = {
  isMulti: boolean;
  id?: string;
  value: Option;
  onChange: (selected: any) => void;
};

export default function InvestorOrganizationSelectAll({
  ...props
}: InvestorOrganizationSelectAllType) {
  const { data } = useSWR<AutocompleteResult[]>(
    `/api/people_map/autocomplete/investor_organizations`,
    api.getter,
  );

  return <Select {...props} options={data} isClearable />;
}
